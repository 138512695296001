/* eslint-disable react/jsx-pascal-case */
import React, { Fragment } from "react";
import "./uf_content.scss";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import NX_Form_Dialog from "./form_wrappers/nx_form_dialog";
import SyncIcon from "@mui/icons-material/Sync";
import DeleteItemModal from "../../components/modals/DeleteItemModal";
import Nx_Grid_Filters from "./nx_grid_filters";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "../ui_components/layouts/Grid/Grid";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { CSVLink } from "react-csv";
import data_types from "../data/data_types";

class UF_Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editItem: null,
      deleteItem: null,
      exportData: [],
      filters: {},
    };
    this.fetchData = () => {};
    this.csvLink = React.createRef();
    this.lastUpdate = new Date().getTime();
  }

  componentWillReceiveProps(props) {
    if (props.buttons) {
      if (props.edit) {
        props.buttons.edit = (params) => {
          return (
            <IconButton
              aria-label="edit"
              color="default"
              onClick={() => this.onEditItem(params.row)}
            >
              <EditIcon />
            </IconButton>
          );
        };
      }
      if (props.delete) {
        props.buttons.delete = (params, callback) => {
          return (
            <IconButton
              aria-label="delete"
              color="default"
              onClick={() => this.onDeleteItem(params.row, callback)}
            >
              <DeleteIcon />
            </IconButton>
          );
        };
      }
    }
  }

  onAddNew = () => {
    const newItem =
      global.UF.dataProvider.datastructure[this.props.table].new();
    if (this.props.beforeAddNew) {
      this.props.beforeAddNew(newItem, () => {
        this.setState({ editItem: newItem });
      });
    } else {
      this.setState({ editItem: newItem });
    }
  };

  onEditItem = (editItem) => {
    this.setState({ editItem });
  };

  onDeleteItem = (deleteItem, callback) => {
    this.setState({ deleteItem });
    this.fetchData = callback;
  };

  deleteItem = (deleteItem) => {
    if (this.props.onDelete) {
      this.props.onDelete(deleteItem, () => {
        this.fetchData();
      });
    } else {
      deleteItem.Delete(async () => {
        if (this.props.afterDelete) {
          await this.props.afterDelete();
        }
        this.fetchData();
      });
    }
  };

  onDialogClose = () => {
    this.lastUpdate = new Date().getTime();
    this.setState({ editItem: null });
  };

  onDeleteModalClose = () => {
    this.lastUpdate = new Date().getTime();
    this.setState({ deleteItem: null });
  };

  getSyncFunction = (fetchData, filters) => {
    this.fetchData = fetchData;
    if (this.props.fetchData) {
      this.props.fetchData(fetchData, filters);
    }
    this.setState({ filters });
  };

  exportData = async () => {
    const filters = this.state.filters;
    if (typeof filters !== "object") {
      global.UF.setAlertVisibility(
        true,
        "The filters are not in right format",
        "error"
      );
      return;
    }

    if (filters.hasOwnProperty("page") && filters.hasOwnProperty("size")) {
      delete filters.page;
      delete filters.size;
    }
    try {
      global.loadingSetVisibility(true);
      let exportData = await global.UF.dataProvider.get_v2(
        this.props.table,
        filters,
        false
      );
      const fields = global.UF.data_structure[this.props.table].fieldsArr;
      const exportHeaders = fields.map((field) => {
        return { label: field.label, key: field.field };
      });
      exportData = this.formatFieldsForExport([...exportData], fields);
      this.setState({ exportData, exportHeaders }, () => {
        this.csvLink.current.link.click(() => {
          global.loadingSetVisibility(false);
        });
      });
    } catch (err) {
      global.UF.setAlertVisibility(true, err.toString(), "error");
      throw new Error(err.toString());
    }
  };

  formatFieldsForExport = (data, fields) => {
    data.forEach((record) => {
      fields.forEach((field) => {
        if (field.datatype === data_types.reference) {
          record[field.field] =
            global.UF.dataProvider.referenceProvider.getReferenceLabel(
              field.reference,
              record[field.field]
            );
        }

        if (
          field.datatype === data_types.json ||
          field.datatype === data_types.string
        ) {
          record[field.field] =
            typeof record[field.field] === "string" &&
            record[field.field].includes(",")
              ? record[field.field].replace(/,/g, ";")
              : record[field.field];
        }

        if (field.datatype === data_types.boolean) {
          record[field.field] = record[field.field] == "1" ? "Yes" : "No";
        }
      });
    });
    return data;
  };

  renderTools = () => {
    return (
      <Grid style={{ gridAutoFlow: "column", width: "auto", gap: "10px" }}>
        {this.props.add ? (
          <Grid className="uf_content_wrapper_for_tools">
            <Tooltip title={"Add"}>
              <IconButton onClick={this.onAddNew}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        ) : null}
        {this.props.sync ? (
          <Grid className="uf_content_wrapper_for_tools">
            <Tooltip title={"Sync"} placement="bottom-start">
              <IconButton onClick={() => this.fetchData()}>
                <SyncIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        ) : null}
        {this.props.export ? (
          <Grid className="uf_content_wrapper_for_tools">
            <Tooltip title={"Export"} placement="bottom-start">
              <IconButton onClick={() => this.exportData()}>
                <DriveFolderUploadIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        ) : null}
        {this.renderAdditionalTools()}
      </Grid>
    );
  };

  renderAdditionalTools = () => {
    if (this.props.tools && Object.keys(this.props.tools).length) {
      return Object.keys(this.props.tools).map((tool) => {
        return (
          <Grid className={"uf_content_wrapper_for_tools"} key={tool}>
            {this.props.tools[tool]()}
          </Grid>
        );
      });
    } else {
      return null;
    }
  };

  /** MODALS **/

  renderEditForm = () => {
    return (
      <NX_Form_Dialog
        title={this.props.title}
        helper={this.props.helper}
        editItem={this.state.editItem}
        config={this.props.formConfig}
        table={this.props.table}
        speakerTypeChange={this.props.speakerTypeChange}
        onBeforeDataItemSave={this.props.onBeforeDataItemSave}
        onDataItemsLoad={this.props.onDataItemsLoad}
        onSave={this.props.onSave}
        afterSave={this.props.afterSave}
        onDialogClose={this.onDialogClose}
      />
    );
  };

  renderDeleteModal = () => {
    return (
      <DeleteItemModal
        open={this.state.deleteItem !== null}
        onClose={this.onDeleteModalClose}
        onDelete={() => this.deleteItem(this.state.deleteItem)}
      />
    );
  };

  /** END MODALS **/

  render() {
    return (
      <Fragment>
        {this.state.deleteItem && this.renderDeleteModal()}
        {this.state.editItem && this.renderEditForm()}
        <Grid className={"uf_content_wrapper"}>
          <Grid className={"uf_content_upper"}>
            <Grid className={"uf_content_title"}>{this.props.title}</Grid>
            {this.renderTools()}
          </Grid>
          <Nx_Grid_Filters
            id={this.props.id}
            table={this.props.table}
            permission={this.props.permission}
            additional_data={this.props.additional_data}
            onDataItemsLoad={this.props.onDataItemsLoad}
            lastUpdate={this.lastUpdate}
            buttons={this.props.buttons}
            customFiltering={this.props.customFiltering}
            popOverButtons={
              this.props.popOverButtons ? this.props.popOverButtons : {}
            }
            fetchData={this.fetchData}
            syncData={this.getSyncFunction}
            onEdit={this.onEditItem}
            onDelete={this.onDeleteItem}
            attendeeType={this.props.attendeeType}
            registrationType={this.props.registrationType}
            gridProps={this.props.gridProps}
            rowDoubleClick={this.props.rowDoubleClick}
            initialSearch={this.props.initialSearch}
            rowHeight={this.props.rowHeight}
          />
        </Grid>
        <Grid hidden={true}>
          <CSVLink
            data={this.state.exportData}
            separator={","}
            headers={this.state.exportHeaders}
            ref={this.csvLink}
            filename={`${global.UF.data.currentEvent.title}_${this.props.table}.csv`}
          />
        </Grid>
      </Fragment>
    );
  }
}

export default UF_Content;
