export default {
    string: 'string',
    number: 'number',
    datetime: 'datetime',
    date: 'date',
    time: 'time',
    boolean: 'boolean',
    reference: 'reference',
    color: 'color',
    json: 'json',
    select : 'select',
    file : 'file'
}
