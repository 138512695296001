import React from 'react';
import '../Login/log_in.scss'
import UF_Button from "../../../nix_components/ui_components/controls/nx_button.js";
import withRouter from "../../../nix_components/components/withRouter";
import bcrypt from "bcryptjs-react";
import Nx_Ctrl_TextInput from "../../../nix_components/ui_components/controls/nx_ctrl_textinput";

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password : '',
            repeatPassword : '',
            isError: false,
            errMsg: ''
        }
        this.user = null;
    }

    onChange = (val, field) => {
        this.state[field] = val;
        this.forceUpdate()
    }

    componentDidMount() {
        if(this.props.router.location.search.length) {
            let uid = this.props.router.location.search.split('=')[1]
            window.UF.dataProvider.get(`users`, {forgot_password_token : uid}, (users) => {
                this.user = users[0]
                this.forceUpdate()
            })
        }
        else {
            alert(`Your forgot password token don't match`)
            window.location.href = '/'
        }
    }

    cryptPassword = (password, callback) => {
        bcrypt.genSalt(10, function(err, salt) {
            bcrypt.hash(password, salt, function(err, hash) {
                if(err) {
                    console.log(err)
                    callback(err)
                }
                callback(hash)
            });
        });
    }

    handleValidation = () => {
        let valid = false;
        if(this.state.password !== null && this.state.repeatPassword !== null && this.state.password.length && this.state.repeatPassword.length) {
            if(this.state.password.trim() === this.state.repeatPassword.trim()) {
                this.state.isError = false;
                this.state.errMsg = ''
                valid = true
            }
            else {
                this.state.isError = false;
                this.state.errMsg = `The passwords don't match`
                valid = false;
            }
        }
        else {
            this.state.isError = false;
            this.state.errMsg = 'Both fields are required'
            valid = false;
        }
        this.forceUpdate()
        return valid
    }

    handleResetPassword = () => {
        if(this.handleValidation()) {
            if(this.user !== null && this.user !== undefined) {
                this.cryptPassword(this.state.password, (hash) => {
                    this.user.password2 = hash
                    this.user.forgot_password_token = '';
                    this.user.Save(() => {
                        this.props.router.navigate('/login');
                    })
                })
            }
        }
    }

    onKeyDown = (e) => {
        if(e.keyCode == '13') {
            this.handleResetPassword()
        }
    }

    findImage = () => {
        const url = window.location.href;
        if(url.includes('realty')) {
            return "realtyon.png"
        }
        else if(url.includes('financemagnates')) {
            return "financemagnates.png"
        }
        else if(url.includes('ultimatefintech')) {
            return "ifxexpo.png"
        }
        else {
            return "ifxexpo.png"
        }
    }

    render() {
        return (
            <div className="uf_login">
                <div className="uf_login_card">
                    <div className="uf_login_logo_section">
                        <div className="uf_login_logo">
                            <img src={`./assets/images/${this.findImage()}`} alt={"logo"}/>
                        </div>
                    </div>
                    <div className="uf_login_login">
                        <div className="uf_login_text">
                            Reset password
                        </div>
                        <div className="uf_login_textfield_wrapper">
                            <Nx_Ctrl_TextInput
                                onChange={(val) => this.onChange(val, `password`)}
                                label="Password"
                                type={"password"}
                                error={this.state.errMsg.length > 0}
                                helperText={this.state.errMsg}
                                value={this.state.password}
                                onKeyDown={this.onKeyDown}
                                className={"uf_login_textfield"}
                                inputStyle={{height : "25px", borderRadius : "10px"}}
                                inputLabelStyle={{fontSize : "16px", fontFamily : "Poppins", fontStyle : "normal", fontWeight : "500"}}
                            />
                        </div>
                        <div className="uf_login_textfield_wrapper">
                            <Nx_Ctrl_TextInput
                                onChange={(val) => this.onChange(val, `repeatPassword`)}
                                label="Repeat password"
                                type={"password"}
                                error={this.state.errMsg.length > 0}
                                helperText={this.state.errMsg}
                                value={this.state.repeatPassword}
                                onKeyDown={this.onKeyDown}
                                className={"uf_login_textfield"}
                                inputStyle={{height : "25px", borderRadius : "10px"}}
                                inputLabelStyle={{fontSize : "16px", fontFamily : "Poppins", fontStyle : "normal", fontWeight : "500"}}
                            />
                        </div>
                        <div className={"uf_login_button_container"}>
                            <UF_Button label="Reset" className="uf_login_login_button" onClick={this.handleResetPassword}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}


export default withRouter(ResetPassword);
