import React, { Component } from "react";
import { Dialog, Typography } from "@mui/material";
import PropTypes from "prop-types";
import UF_Button from "../../nix_components/ui_components/controls/nx_button.js";
import Nx_Ctrl_TextInput from "../../nix_components/ui_components/controls/nx_ctrl_textinput";
import "./set_password_modal.scss";
import Nx_Dialog from "../../nix_components/ui_components/dialogs/nx_dialog";
import Grid from "../../nix_components/ui_components/layouts/Grid/Grid";

class SetPasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: null,
      confirmPassword: null,
      isError: false,
      errMsg: "",
    };
  }

  buttons = [
    {
      label: "Cancel",
      variant: "outlined",
      onClick: this.props.onClose,
    },
    {
      label: "Save",
      onClick: () => this.setPassword(),
    },
  ];

  setPassword = () => {
    this.state.isError = this.handleValidation();
    if (!this.state.isError) {
      this.state.errMsg = "";
      this.props.onSetPassword(this.state.password);
      this.props.onClose();
    } else {
      if (!this.state.errMsg.length) {
        this.state.errMsg = `Passwords doesn't match`;
      }
    }
    this.forceUpdate();
  };

  handleValidation = () => {
    let valid = false;
    if (this.state.password !== null && this.state.confirmPassword !== null) {
      valid = this.state.password === this.state.confirmPassword;
    } else {
      this.state.errMsg = "Both fields are required";
    }
    this.forceUpdate();
    return !valid;
  };

  onChange = (val, field) => {
    this.state[field] = val;
    this.forceUpdate();
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.setPassword();
    }
  };

  render() {
    return (
      <Nx_Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        title={`Set user password`}
        buttons={this.buttons}
      >
        <Grid className={"set_password_wrapper"}>
          <Nx_Ctrl_TextInput
            onChange={(val) => this.onChange(val, `password`)}
            type={"password"}
            label={"Password"}
            error={this.state.isError}
            helperText={this.state.errMsg}
            value={this.state.password}
            onKeyDown={this.onKeyDown}
          />
          <Nx_Ctrl_TextInput
            onChange={(val) => this.onChange(val, `confirmPassword`)}
            label={"Confirm password"}
            type={"password"}
            error={this.state.isError}
            helperText={this.state.errMsg}
            value={this.state.confirmPassword}
            onKeyDown={this.onKeyDown}
          />
        </Grid>
      </Nx_Dialog>
    );
  }
}

SetPasswordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

export default SetPasswordModal;
