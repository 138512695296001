import React, {useState} from 'react'
import {Editor} from "react-draft-wysiwyg"
import {EditorState} from 'draft-js'
import {stateFromHTML} from 'draft-js-import-html';
import { stateToHTML } from "draft-js-export-html"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

const UF_WYSIWYG = (props) => {
    const [editorState, setEditorState] = useState(() => {
        if (props.value) {
            return EditorState.createWithContent(stateFromHTML(props.value))
        } else {
            return EditorState.createEmpty()
        }
    })


    const onEditorStateChange = (editorState) => {
        const contentState = editorState.getCurrentContent()
        setEditorState(editorState)
        props.onChange(stateToHTML(contentState))
    }

    return (
        <Editor
            editorState={editorState}
            editorClassName={`nx_textfield nx_editor`}
            placeholder={"Description"}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            onEditorStateChange={onEditorStateChange}
        />
    )


}

export default UF_WYSIWYG