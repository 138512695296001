import React, { Fragment } from "react";
import UF_Content from "../../../../nix_components/components/UF_Content";
import data_types from "../../../../nix_components/data/data_types";
import { validators } from "../../../../nix_components/data/data_validators";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { IconButton } from "@mui/material";
import ReorderIcon from "@mui/icons-material/Reorder";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import ReorderModal from "../../../../components/modals/ReorderModal";
import UF_WYSIWYG from "../../../../controls/UF_WYSIWYG";
import TransferDataModal from "../../../../components/modals/TransferDataModal";
import TranslateIcon from '@mui/icons-material/Translate';
import AgendaTranslationsModal from "../../../../components/modals/AgendaTranslationsModal";

class AgendaTopics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reorderOpen: false,
      transferOpen: false,
      permission: "agenda",
      scopeCondition: false,
      loaded: false,
      setTranslations: false,
      setTranslationsForId: false,
      setTranslationsForName: false,
    };
  }

  buttons = {
    setTranslations: (params) => {
      return (
        <Fragment>
          <IconButton
            aria-label="languages"
            color="default"
            onClick={() => this.setTranslationsState(params.row.id, params.row.title)}
          >
            <TranslateIcon />
          </IconButton>
        </Fragment>
      )
    }
  };

  setTranslationsState = (agendaId, agendaTitle) => {
    this.setState({setTranslations: true});
    this.setState({setTranslationsForId: agendaId});
    this.setState({setTranslationsForName: agendaTitle});
  }

  setTranslations = () => {
    return (
      <AgendaTranslationsModal 
        open={this.state.setTranslations}
        eventId={this.props.event_id}
        modelId={this.state.setTranslationsForId}
        modelName={this.state.setTranslationsForName}
        onClose={() => this.setState({ setTranslations: false })}
      />
    );
  }

  tools = {
    reorder: () => {
      return this.state.scopeCondition ? (
        <Tooltip title={"Reorder"}>
          <IconButton onClick={() => this.setState({ reorderOpen: true })}>
            <ReorderIcon />
          </IconButton>
        </Tooltip>
      ) : null;
    },
    transfer: () => {
      return this.state.scopeCondition ? (
        <Tooltip title={"Transfer"}>
          <IconButton onClick={() => this.setState({ transferOpen: true })}>
            <MoveUpIcon />
          </IconButton>
        </Tooltip>
      ) : null;
    },
  };

  formConfig = {
    rows: [
      [
        {
          field: "published",
          datatype: data_types.boolean,
          label: "Published",
        },
        {},
      ],
      [
        "title",
        {
          label: "Schedule type",
          field: "schedule_type",
          reference: "schedule_types",
          datatype: data_types.reference,
        },
        {
          label: "Image",
          field: "image",
          datatype: data_types.file,
          requirements: { width: 1250, height: 530 },
        },
      ],
      [
        {
          label: "Date",
          field: "date",
          datatype: data_types.date,
          validators: [
            validators.isDateBeforeToday,
            {
              msg: "Date must be between event's start and end date",
              func: (value) => {
                let pickedDate = new Date(value).getTime();
                let eventStartDate = new Date(
                  window.UF.data.currentEvent.start_date
                ).getTime();
                let eventEndDate = new Date(
                  window.UF.data.currentEvent.end_date
                ).getTime();
                return !(
                  pickedDate >= eventStartDate && pickedDate <= eventEndDate
                );
              },
            },
          ],
        },
        {
          label: "Start Time",
          field: "start_time",
          datatype: data_types.time,
        },
        {
          label: "End Time",
          field: "end_time",
          datatype: data_types.time,
        },
      ],
      [
        {
          label: "Moderators",
          field: "moderators",
          datatype: data_types.reference,
          reference: "speakers",
          published: 1,
          multiple: true,
        },
      ],
      [
        {
          label: "Speakers",
          field: "speakers",
          datatype: data_types.reference,
          reference: "speakers",
          published: 1,
          multiple: true,
        },
      ],
      [
        {
          label: "Description",
          field: "description",
          multiline: true,
          customControl: (props, onValueChange) => {
            return (
              <UF_WYSIWYG
                {...props.fieldConfig}
                onChange={onValueChange}
                value={props.dataItem[props.fieldConfig.field]}
              />
            );
          },
        },
      ],
    ],
  };

  gridProps = {
    fields: [
      "title",
      "date",
      "schedule_type",
      "moderators",
      "speakers",
      "ordering",
      "published",
      "created_at",
    ],
  };

  handleBeforeAddNew = (dataItem, callback) => {
    dataItem.event_id = global.UF.event_id;
    global.UF.makeRequest(
      `POST`,
      `/api/get-ordering`,
      { table: "agenda_topics" },
      true,
      (data) => {
        dataItem.ordering = data.body.ordering;
        if (callback) {
          callback();
        }
      },
      (error) => {
        console.error(error);
      },
      true,
      false
    );
  };

  fetchData = () => {};

  getFetchData = (fetchData) => {
    this.fetchData = fetchData;
  };

  renderReorderingModal = () => {
    return (
      <ReorderModal
        open={this.state.reorderOpen}
        onClose={() =>
          this.setState({ reorderOpen: false }, () => this.fetchData())
        }
        table={"agenda_topics"}
        onClick={this.fetchData}
      />
    );
  };

  renderTransferDataModal = () => {
    return (
      <TransferDataModal
        open={this.state.transferOpen}
        onClose={() =>
          this.setState({ transferOpen: false }, () => this.fetchData())
        }
        table={"agenda_topics"}
      />
    );
  };

  findCurrentPermission = () => {
    const user =
      typeof localStorage.getItem("user") === "string"
        ? JSON.parse(localStorage.getItem("user"))
        : localStorage.getItem("user");
    const scopes = user.hasOwnProperty("scopes")
      ? typeof user.scopes === "string"
        ? JSON.parse(user.scopes)
        : user.scopes
      : null;
    this.state.scopeCondition =
      scopes.findIndex(
        (permission) =>
          permission.split(":")[0] === this.state.permission &&
          permission.includes("write")
      ) > -1;
    this.setState({ loaded: true });
  };

  componentDidMount() {
    this.findCurrentPermission();
  }

  render() {
    return (
      <Fragment>
        {this.state.reorderOpen && this.renderReorderingModal()}
        {this.state.transferOpen && this.renderTransferDataModal()}
        {this.state.setTranslations && this.setTranslations()}
        <UF_Content
          table="agenda_topics"
          id={this.props.event_id}
          permission="agenda"
          title="Agenda Topics"
          buttons={this.buttons}
          edit={this.state.scopeCondition}
          delete={this.state.scopeCondition}
          export={this.state.scopeCondition}
          tools={this.tools}
          fetchData={this.getFetchData}
          formConfig={this.formConfig}
          gridProps={this.gridProps}
          add={this.state.scopeCondition}
          sync={this.state.scopeCondition}
          beforeAddNew={this.handleBeforeAddNew}
        />
      </Fragment>
    );
  }
}

export default AgendaTopics;
