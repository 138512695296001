import React, { Fragment } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Events from "./Events";
import Awards from "./Awards";
import Administrator from "./Administrator";
import Header from "../../nix_components/ui_components/components/Header/Header.js";
import Menu from "../../nix_components/ui_components/components/Menu/Menu.js";
import Print from "./Print/Print";
import Grid from "../../nix_components/ui_components/layouts/Grid/Grid";
import NoScopes from "./AlertPages/NoScopes";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import Nx_Loader from "../../nix_components/components/nx_loader";

class Secure extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      awardItems: null,
      eventItems: null,
      showMenu: true,
      loaded: false,
      initializing: true,
    };
    this.menuItems = [];
  }

  getMenuItems = (items) => {
    if (this.props.user) {
      let permissions = JSON.parse(this.props.user.scopes);
      permissions.forEach((permission) => {
        let scopeName = permission.split(":")[0];
        let scope = permission.split(":")[1];
        items.forEach((item) => {
          if (item.scope !== null && item.scope !== undefined) {
            if (item.scope.length) {
              if (item.scope === scopeName) {
                item[scope] = true;
              }
            } else {
              item[scope] = true;
            }
          }
        });
      });
      this.menuItems = items;
      this.forceUpdate();
    } else {
      this.menuItems = [];
    }
  };

  fetchItems = async () => {
    const events = await global.UF.dataProvider.get_v2(`events`, null);
    const awards = await global.UF.dataProvider.get_v2(`awards`, null);
    global.UF.data.events = events;
    global.UF.data.currentEvent = events.length ? events[0] : {};
    global.UF.data.awards = awards;
    global.UF.data.currentAward = awards.length ? awards[0] : {};
    global.UF.event_id = events.length ? events[0].id : null;
    global.UF.award_id = awards.length ? awards[0].id : null;
    const eventItems = events.map((event, index) => {
      return {
        title: event.name,
        url: `events/${event.id}`,
        index: index,
        event_id: event.id,
      };
    });
    const awardItems = awards.map((award, index) => {
      return {
        title: award.name,
        url: `awards/${award.id}`,
        index: index,
        award_id: award.id,
      };
    });
    this.setState({ eventItems, awardItems }, async () => {
      this.handleSelectedItem();
      await this.getEventSettings();
    });
  };

  handleSelectedItem = () => {
    const pathname = window.location.pathname;
    const items = [...this.state.eventItems, ...this.state.awardItems];
    const currentItem = items.find((item) => pathname.includes(item.url));
    if (currentItem) {
      currentItem.selected = true;
      if (currentItem.hasOwnProperty("event_id")) {
        global.UF.event_id = currentItem.event_id;
        global.UF.data.currentEvent = currentItem;
      } else if (currentItem.hasOwnProperty("award_id")) {
        global.UF.award_id = currentItem.award_id;
        global.UF.data.currentAward = currentItem;
      }
    }
  };

  getEventSettings = async () => {
    try {
      if (global.UF.event_id) {
        const EVENT_SETTINGS = await global.UF.makeRequest_v2(
          `POST`,
          `/api/init`,
          { event_id: global.UF.event_id, force: true },
          true
        );
        if (EVENT_SETTINGS.body) {
          global.UF.EVENT_SETTINGS = EVENT_SETTINGS.body;
          localStorage.setItem(
            "event_settings",
            JSON.stringify(EVENT_SETTINGS.body)
          );
          document.title = global.UF.EVENT_SETTINGS.DOCUMENT_TITLE;
        }
      } else {
        global.UF.setAlertVisibility(
          true,
          "Please choose current event to start using application",
          "error"
        );
        console.error("Please choose current event to start using application");
      }
    } catch (err) {
      global.UF.setAlertVisibility(
        true,
        `Error occurred while trying to get event settings. \nError : ${err.toString()}`,
        "error"
      );
      console.error(
        `Error occurred while trying to get event settings. \nError : ${err}`
      );
    }
  };

  componentDidMount = async () => {
    try {
      await this.fetchItems();
    } catch (err) {
      console.error(`Error in Secure : ${err}`);
      global.UF.setAlertVisibility(true, err.toString(), "error");
      throw new Error(err);
    }
    if (global.UF.EVENT_SETTINGS) {
      document.title = global.UF.EVENT_SETTINGS.DOCUMENT_TITLE;
    }
    if (window.location.pathname == "/" || window.location.pathname == "") {
      window.location.pathname = `/events/${global.UF.event_id}`;
    }
    this.setState({ loaded: true });
  };

  handleMenu = () => {
    const showMenu = !this.state.showMenu;
    this.setState({ showMenu });
  };

  renderElement = () => {
    if (this.state.eventItems || this.state.awardItems) {
      return (
        <BrowserRouter>
          <Grid
            fullScreen={true}
            style={{
              backgroundColor: "#F8FAFB",
              gridTemplateColumns: `${
                this.state.showMenu ? "321px auto" : "auto"
              }`,
              overflow: "hidden",
            }}
          >
            <IconButton
              style={{
                position: "absolute",
                top: "10px",
                left: "10px",
                zIndex: "1000",
              }}
              onClick={this.handleMenu}
            >
              {this.state.showMenu ? (
                <ArrowBack
                  style={{
                    color: `${
                      this.state.showMenu ? "#F8FAFB" : "rgba(30, 30, 30, 0.70)"
                    }`,
                  }}
                />
              ) : (
                <MenuIcon
                  style={{
                    color: `${
                      this.state.showMenu ? "#F8FAFB" : "rgba(30, 30, 30, 0.70)"
                    }`,
                  }}
                />
              )}
            </IconButton>
            <Grid
              style={{
                width: `${this.state.showMenu ? "321px" : "0px"}`,
                transition: `width 1s ease, opacity 1s ease`,
                backgroundColor: "rgba(30, 30, 30, 0.70)",
                overflow: "hidden",
                overflowY: "auto",
                opacity: `${this.state.showMenu ? "1" : "0"}`,
              }}
              hidden={!this.state.showMenu}
            >
              <Menu items={this.menuItems} />
            </Grid>
            <Grid style={{ gridTemplateRows: "89px auto", rowGap: "31px" }}>
              <Grid className={"border_header_right"}>
                <Header
                  eventItems={this.state.eventItems}
                  awardItems={this.state.awardItems}
                />
              </Grid>
              <Grid
                style={{ width: "98%", height: "98%", justifySelf: "center" }}
              >
                <Routes>
                  <Route
                    path="/events/:event_id/*"
                    element={
                      <Events
                        getMenuItems={this.getMenuItems}
                        menuItems={this.menuItems}
                      />
                    }
                  />
                  <Route
                    path="/awards/:award_id/*"
                    element={
                      <Awards
                        fetchMenuItems={this.fetchItems}
                        getMenuItems={this.getMenuItems}
                        menuItems={this.menuItems}
                      />
                    }
                  />
                  <Route
                    path="/administrator/*"
                    element={
                      <Administrator
                        fetchMenuItems={this.fetchItems}
                        getMenuItems={this.getMenuItems}
                        menuItems={this.menuItems}
                      />
                    }
                  />
                  <Route path={"/print"} element={<Print />} />
                  <Route
                    path={"/*"}
                    element={
                      <Events
                        getMenuItems={this.getMenuItems}
                        menuItems={this.menuItems}
                      />
                    }
                  />
                </Routes>
              </Grid>
            </Grid>
          </Grid>
        </BrowserRouter>
      );
    }
  };

  render() {
    if (this.props.user && this.props.user.hasOwnProperty("scopes")) {
      if (typeof this.props.user.scopes == "string") {
        if (!JSON.parse(this.props.user.scopes).length) {
          return <NoScopes />;
        }
      }
    }
    if (!this.state.loaded) {
      return <Nx_Loader show={!this.state.loaded} />;
    } else {
      return <Fragment>{this.renderElement()}</Fragment>;
    }
  }
}

export default Secure;
