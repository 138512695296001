import data_types from "./data_types";

const isNull = (value) => {
  return value == null;
};

const isEmpty = (value) => {
  if (!isNull(value)) {
    return value.trim().length == 0;
  } else {
    return true;
  }
};

const isNotEmail = (email) => {
  return !String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const isDateBeforeStartDate = (start, end) => {
  if (start !== null && end !== null) {
    return new Date(start).getTime() + 86400 > new Date(end).getTime();
  } else {
    return true;
  }
};

const isTimeBeforeStartTime = (start, end) => {
  if (start !== null && end !== null) {
    return new Date(start).getTime() > new Date(end).getTime();
  } else {
    return false;
  }
};

const isDateBeforeToday = (value) => {
  if (value !== null && value !== undefined) {
    return new Date().getTime() > new Date(value).getTime();
  } else {
    return true;
  }
};

const isNotWebsite = (value) => {
  return false;
  // const websiteConfig =
  //   /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9-_]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
  //   /^https?:\/\/(www\.)?[a-zA-Z0-9-]+\.[a-z]{2,}(\/[a-zA-Z0-9-]+)*\/?$/;
  // if (!isNull(value)) {
  //   return !websiteConfig.test(value);
  // } else {
  //   return true;
  // }
};

const isNegative = (value) => {
  if (value !== null && value !== undefined) {
    return !Number(value) < 0;
  } else {
    return true;
  }
};

const validators = {
  isNull: {
    msg: "Field is required",
    func: isNull,
  },
  isEmpty: {
    msg: "Field cannot be empty",
    func: isEmpty,
  },
  isNotEmail: {
    msg: "Email must be in correct email format",
    func: isNotEmail,
  },
  isWebsite: {
    msg: "Website must be in correct url format",
    func: isNotWebsite,
  },
  isDateBeforeToday: {
    msg: `Date can't be before today`,
    func: isDateBeforeToday,
  },
  isNegative: {
    msg: `Value cannot be negative`,
    func: isNegative,
  },
  isDateBeforeStartDate: {
    name: "beforeStartDate",
    msg: `Date can't be before start date`,
    func: isDateBeforeStartDate,
  },
  isTimeBeforeStartTime: {
    name: "beforeStartTime",
    msg: `Time can't be before start time`,
    func: isTimeBeforeStartTime,
  },
};

const setCustomValidations = (field) => {
  if (field.datatype === data_types.number) {
    field.validators.push(validators.isNegative);
  }
  if (field.name.includes("email")) {
    if (field.validators.length) field.validators.push(validators.isNotEmail);
  }
  if (field.name.includes("website")) {
    field.validators.push(validators.isWebsite);
  }
};

const setValidations = (table_structure) => {
  table_structure.fieldsArr.forEach((field) => {
    let validatorArr = [];
    if (
      field.nullable == false &&
      validatorArr.indexOf(validators.isNull) < 0
    ) {
      validatorArr.push(validators.isNull);
    }
    if (field.hasOwnProperty("validators")) {
      field.validators = [...validatorArr, ...field.validators];
    } else {
      field.validators = validatorArr;
    }

    setCustomValidations(field);
    if (!field.hasOwnProperty("validate")) {
      field.validate = (dataItem, value, returnMsg = true) => {
        let isNotValid = false;
        field.validators.forEach((validator) => {
          if (isNotValid === false) {
            if (validator.func(value, dataItem)) {
              if (returnMsg) {
                isNotValid = validator.msg;
              } else {
                isNotValid = true;
              }
            }
          }
        });
        return isNotValid;
      };
    }
  });

  table_structure.validate = (dataItem) => {
    let isValid = true;
    table_structure.fieldsArr.forEach((field) => {
      if (isValid == false) {
        return;
      }
      if (!table_structure.ignoreValidation?.includes(field.name)) {
        if (field.validate(dataItem, dataItem[field.name], true)) {
          console.log(`The validation failed for field : `, field.name);
          isValid = false;
        }
      } else {
        isValid = true;
      }
    });
    return isValid;
  };
};

export { setValidations, validators };
