import React, {Fragment} from 'react'
import UF_Button from "../../nix_components/ui_components/controls/nx_button.js"
import Dialog from "@mui/material/Dialog/Dialog"
import Typography from "@mui/material/Typography/Typography"
import Nx_Ctrl_Checkbox from "../../nix_components/ui_components/controls/nx_ctrl_checkbox"
import {CSVLink} from "react-csv"
import withRouter from "../../nix_components/components/withRouter"
import Alert from '@mui/material/Alert'
import IconButton from "@mui/material/IconButton/IconButton"
import CloseIcon from '@mui/icons-material/Close'

class ExportNomineesModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            approvedNominees: false,
            pendingNominees: false,
            rejectedNominees: false,
            data: [],
            showAlert: false
        }
        this.csvLink = React.createRef()
    }

    exportFields = [
        {field: 'name', label: 'Fullname'},
        {field: 'email', label: 'Email'},
        {field: 'nominated_by', label: 'Nominated by'},
        {field: 'url', label: 'Website'},
        {field: 'nomination_id', label: 'Awards'},
        {field: 'description', label: 'Description'},
        {field: 'eligibility', label: 'Eligibility'},
        {field: 'status', label: 'Status'}
    ]

    onChange = (value, state) => {
        this.state[state] = value
        this.forceUpdate()
    }

    onSelectAllChange = () => {
        this.state.approvedNominees = true
        this.state.pendingNominees = true
        this.state.rejectedNominees = true
        this.forceUpdate()
    }

    onExportClick = () => {
        global.UF.dataProvider.get(`nominees`, {}, (nominees) => {
            if (this.state.approvedNominees === false) {
                nominees = nominees.filter(nominee => nominee.status !== 'approved')
            }
            if (this.state.pendingNominees === false) {
                nominees = nominees.filter(nominee => nominee.status !== 'pending')
            }
            if (this.state.rejectedNominees === false) {
                nominees = nominees.filter(nominee => nominee.status !== 'rejected')
            }

            this.state.data = this.generateItemsForExporting(nominees)
            if (this.state.data.length === 0) {
                this.state.showAlert = true
                this.forceUpdate()
            } else {
                this.state.showAlert = false
                this.forceUpdate(() => {
                    this.csvLink.current.link.click()
                })
            }
        })
    }

    formatValueForExporting = (value, type) => {
        switch(type) {
            case 'boolean':
                return value === 1 ? 'Yes' : 'No'
            case 'string':
                return value ? value : '######'
        }
    }

    generateItemsForExporting = (data) => {
        let temp = []
        data.forEach(item => {
            let tempObj = {}
            this.exportFields.forEach(field => {
                if (field.field === 'nomination_id') {
                    let tempAwards = []
                    const awardIds = JSON.parse(item['nomination_id'])
                    if (awardIds.length) {
                        awardIds.forEach(awardId => {
                            let award = global.UF.dataProvider.referenceProvider.getReferenceLabel(`nominations`, awardId)
                            tempAwards.push(award)
                        })
                        tempObj['Awards'] = tempAwards.length ? tempAwards.toString() : '#####'
                    } else {
                        tempObj['Awards'] = '#####'
                    }
                } else {
                    tempObj[field.label] = this.formatValueForExporting(
                        item[field.field],
                        item.table_structure.fieldsArr.find(itemField => itemField[`Field`] === field.field).datatype
                    )
                }
            })
            temp.push(tempObj)
        })
        return temp
    }

    componentDidMount = () => {
        
    }

    render() {
        return (
            <Fragment>
                <Dialog
                    open={this.props.open}
                    keepMounted
                    onClose={this.props.onClose}
                    aria-describedby='alert-dialog-slide-description'
                >
                    <div className='nx_modal_wrapper'>
                        <div className='nx_modal_title'>
                            <Typography variant='h5'>Export data</Typography>
                        </div>
                        <div style={{display: this.state.showAlert ? 'block' : 'none'}}>
                            <Alert severity='warning' action={
                                <IconButton
                                    aria-label='close'
                                    color='inherit'
                                    size='small'
                                    onClick={() => {
                                        this.setState({showAlert: false})
                                    }}
                                >
                                    <CloseIcon fontSize='inherit' />
                                </IconButton>
                            }>
                                <strong>There is no data to be exported</strong>
                            </Alert>
                        </div>
                        <div>
                            <Nx_Ctrl_Checkbox 
                                label={'Select all'}
                                onChange={this.onSelectAllChange}
                                value={this.state.approvedNominees && this.state.pendingNominees && this.state.rejectedNominees}
                            />
                        </div>
                        <div>
                            <Nx_Ctrl_Checkbox 
                                label={'Approved Nominees'}
                                onChange={(value) => this.onChange(value, 'approvedNominees')}
                                value={this.state.approvedNominees}
                            />
                        </div>
                        <div>
                            <Nx_Ctrl_Checkbox 
                                label={'Pending Nominees'}
                                onChange={(value) => this.onChange(value, 'pendingNominees')}
                                value={this.state.pendingNominees}
                            />
                        </div>
                        <div>
                            <Nx_Ctrl_Checkbox 
                                label={'Rejected Nominees'}
                                onChange={(value) => this.onChange(value, 'rejectedNominees')}
                                value={this.state.rejectedNominees}
                            />
                        </div>
                        <div className="nx_buttons">
                            <UF_Button onClick={this.props.onClose} variant={'outlined'} label={'Cancel'}/>
                            <UF_Button label={'Export'} onClick={this.onExportClick}/>
                        </div>
                        <div style={{display: 'none'}}>
                            <CSVLink data={this.state.data} ref={this.csvLink}
                                     filename={`${global.UF.data.currentEvent.title}_nominees.csv`}/>
                        </div>
                    </div>
                </Dialog>
            </Fragment>
        )
    }

}

export default withRouter(ExportNomineesModal)