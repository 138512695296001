import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs"
import {DatePicker} from "@mui/x-date-pickers/DatePicker"
import './controls.scss'
import data_types from "../../data/data_types"
import moment from "moment"


function UF_DateTimePicker(props) {

    const onChange = (event) => {
        if(event && event.$d) {
            let date = new Date(event.$d)
            if(props.fieldConfig.datatype === data_types.date) {
                date = moment(date).format("YYYY-MM-DD")
            }
            props.onChange(date)
        }
        else {
            props.onChange(null);
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={props.fieldConfig.label}
                inputFormat={props.fieldConfig.datatype === data_types.datetime ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD"}
                disabled={props.disabled}
                value={props.value !== null ? new Date(props.value) : null}
                onChange={onChange}
                renderInput={(params) => <TextField size={'small'} {...params} error={props.error}
                                                    helperText={props.helperText} sx={{width : "100%"}}/>}
                className={'nx_textfield'}
            />
        </LocalizationProvider>
    )
}

UF_DateTimePicker.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    variant: PropTypes.oneOf(['outlined', 'filled', 'standard',]),
    fullWidth: PropTypes.bool,
    autoFocus: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    fontSize: PropTypes.string,
    fontLabelSize: PropTypes.string,
    multiline: PropTypes.bool,
    size: PropTypes.oneOf(['medium', 'small', 'string',]),
}

UF_DateTimePicker.defaultProps = {
    className: '',
    disabled: false,
    fullWidth: true,
    autoFocus: false,
    error: false,
    helperText: '',
    type: 'text',
    fontSize: '12px',
    fontLabelSize: '11px',
    multiline: false,
    size: 'small',
}

export default UF_DateTimePicker
