import React, { Fragment } from 'react'
import { Chart, registerables } from 'chart.js'
import { Line } from 'react-chartjs-2'
import "./scss/statistics.scss"

Chart.register(...registerables)

class WelcomeParty extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      permission: 'statistics',
      eventName: 'IFX INTERNATIONAL Cyprus 2024',
      eventDate: '19-09-2023',
      totalData: {
        registered: 306,
        printed: 1474,
      },
    }

    this.server1 = {
      labels: [
        '7:00', '8:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00'
      ],
      datasets: [
        {
          label: 'Printed',
          backgroundColor: [
            'Orange',
          ],
          fill: false,
          lineTension: 0.5,
          borderColor: 'rgba(0,0,0,1)',
          borderWidth: 2,
          data: [18, 3, 3, 9, 24, 31, 27, 14, 4],
        },
      ],
    }

    this.server1Options = {
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Server 1 Printed by hour',
        },
      },
    }

    this.server2 = {
      labels: [
        '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00'
      ],
      datasets: [
        {
          label: 'Printed',
          backgroundColor: [
            'Orange',
          ],
          fill: false,
          lineTension: 0.5,
          borderColor: 'rgba(0,0,0,1)',
          borderWidth: 2,
          data: [2, 11, 8, 223, 542, 547, 246, 35],
        },
      ],
    }

    this.server2Options = {
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Server 3 Printed by hour',
        },
      },
    }


  }

  render() {
    return (
      <Fragment>
        <div style={{ overflow: 'auto', height: '760px' }}>
          <h1>Welcome Party ({this.state.eventDate}) Statistics for {this.state.eventName}</h1>
          <table style={{ textAlign: 'center' }} className='statistics_table'>
            <thead>
              <tr>
              <th>Registered</th>
              <th>Printed</th>
              </tr>
            </thead>
            <tbody>
              <tr>
              <td>{this.state.totalData.registered}</td>
              <td>{this.state.totalData.printed}</td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <br />

          <div style={{ height: '500px' }}>
            <Line
              data={this.server1}
              options={this.server1Options}
            />
          </div>
          <div style={{ height: '500px' }}>
            <Line
              data={this.server2}
              options={this.server2Options}
            />
          </div>

        </div>
      </Fragment>
    )
  }

}

export default WelcomeParty