import React, { Fragment } from "react";
import UF_Content from "../../../../nix_components/components/UF_Content";
import data_types from "../../../../nix_components/data/data_types";
import withRouter from "../../../../nix_components/components/withRouter";
import {
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import { validators } from "../../../../nix_components/data/data_validators";
import uuid from "react-uuid";
import ConfirmModalEmail from "../../../../components/modals/ConfirmModalEmail";
import Nx_Loader from "../../../../nix_components/components/nx_loader";
import { Tooltip } from "@mui/material";

class RegularRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataItem: null,
      emailItem: null,
      permission: "speaker",
      scopeCondition: false,
      loaded: false,
      currentType: "speaker",
    };
    this.additional_data = ["notes"];
  }

  buttons = {
    registrationStatus: (params) => {
      return params.row.status === "Under review" &&
        this.state.scopeCondition ? (
        <Fragment>
          <IconButton
            aria-label="delete"
            color="default"
            onClick={() => this.handleRegistrationStatus(params.row, true)}
          >
            <CheckCircleIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            color="default"
            onClick={() => this.handleRegistrationStatus(params.row, false)}
          >
            <UnpublishedIcon />
          </IconButton>
        </Fragment>
      ) : null;
    },
  };

  handleSpeakerTypeChange = (e, dataItem) => {
    dataItem.type = e.target.value;
    this.speakerTypeChange(e.target.value);
    this.setState({ speakerType: e.target.value });
  };

  formConfig = {
    rows: [
      [
        {
          field: "",
          customControl: (props) => {
            return (
              <FormControl
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <RadioGroup
                  onChange={(e) =>
                    this.handleSpeakerTypeChange(e, props.dataItem)
                  }
                  row
                  value={
                    props.dataItem.type
                      ? props.dataItem.type
                      : this.state.currentType
                  }
                >
                  <FormControlLabel
                    value="speaker"
                    control={<Radio />}
                    label="Speaker"
                  />
                  <FormControlLabel
                    value="speaker-guest"
                    control={<Radio />}
                    label="Speaker guest"
                  />
                </RadioGroup>
              </FormControl>
            );
          },
        },
      ],
      [
        {
          label: "Speaker",
          datatype: data_types.reference,
          reference: "speakers",
          field: "speaker_id",
          onChange: (dataItem) => {
            const speaker_id = dataItem.speaker_id;
            global.UF.dataProvider.get(
              `speakers`,
              { id: speaker_id },
              (speakers) => {
                if (speakers.length) {
                  const currentSpeaker = speakers[0];
                  dataItem.first_name = currentSpeaker.first_name;
                  dataItem.last_name = currentSpeaker.last_name;
                  dataItem.email = currentSpeaker.email;
                  dataItem.company = currentSpeaker.company;
                  this.setState({ dataItem });
                }
              }
            );
          },
        },
        {
          field: "first_name",
          label: "Firstname",
        },
        {
          field: "last_name",
          label: "Lastname",
        },
      ],
      [
        {
          field: "email",
          label: "Email",
        },
        {
          field: "phone",
          label: "Phone",
        },
        {
          field: "company",
          label: "Company",
        },
      ],
    ],
  };

  gridProps = {
    fields: [
      "first_name",
      "website",
      "package_id",
      "status",
      "ticket_quantity",
      "company",
      "category",
      "subcategory",
      "evidence",
      "notes",
      "created_at",
    ],
  };

  handleRegistrationStatus = (item, bool) => {
    item.status = bool === true ? "Completed" : "Rejected";
    global.UF.dataProvider.get(
      `attendees`,
      { registration_id: item.id, status: "pending" },
      (attendees) => {
        item.Save(() => {
          if (attendees.length) {
            attendees.forEach((attendee) => {
              attendee.status = bool === true ? "approved" : "rejected";
              attendee.Save();
            });
          }
        });
      }
    );
  };

  speakerTypeChange = (type) => {
    if (type === "speaker") {
      global.UF.data_structure["registrations"].fieldsArr
        .find((field) => field.Field === "speaker_id")
        .validators.push(validators.isNull);
      this.formConfig = {
        rows: [
          [
            {
              field: "",
              customControl: (props) => {
                return (
                  <FormControl
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <RadioGroup
                      onChange={(e) =>
                        this.handleSpeakerTypeChange(e, props.dataItem)
                      }
                      row
                      value={
                        props.dataItem.type
                          ? props.dataItem.type
                          : this.state.currentType
                      }
                    >
                      <FormControlLabel
                        value="speaker"
                        control={<Radio />}
                        label="Speaker"
                      />
                      <FormControlLabel
                        value="speaker-guest"
                        control={<Radio />}
                        label="Speaker guest"
                      />
                    </RadioGroup>
                  </FormControl>
                );
              },
            },
          ],
          [
            {
              label: "Speaker",
              datatype: data_types.reference,
              reference: "speakers",
              field: "speaker_id",
              onChange: (dataItem) => {
                const speaker_id = dataItem.speaker_id;
                global.UF.dataProvider.get(
                  `speakers`,
                  { id: speaker_id },
                  (speakers) => {
                    if (speakers.length) {
                      const currentSpeaker = speakers[0];
                      dataItem.first_name = currentSpeaker.first_name;
                      dataItem.last_name = currentSpeaker.last_name;
                      dataItem.email = currentSpeaker.email;
                      dataItem.company = currentSpeaker.company;
                      this.setState({ dataItem });
                    }
                  }
                );
              },
            },
            {
              field: "first_name",
              label: "Firstname",
            },
            {
              field: "last_name",
              label: "Lastname",
            },
          ],
          [
            {
              field: "email",
              label: "Email",
            },
            {
              field: "phone",
              label: "Phone",
            },
            {
              field: "company",
              label: "Company",
            },
          ],
        ],
      };
    } else {
      global.UF.data_structure["registrations"].fieldsArr.find(
        (field) => field.Field === "speaker_id"
      ).validators = [];
      this.formConfig = {
        rows: [
          [
            {
              field: "",
              customControl: (props) => {
                return (
                  <FormControl
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <RadioGroup
                      onChange={(e) =>
                        this.handleSpeakerTypeChange(e, props.dataItem)
                      }
                      row
                      value={
                        props.dataItem.type
                          ? props.dataItem.type
                          : this.state.currentType
                      }
                    >
                      <FormControlLabel
                        value="speaker"
                        control={<Radio />}
                        label="Speaker"
                      />
                      <FormControlLabel
                        value="speaker-guest"
                        control={<Radio />}
                        label="Speaker guest"
                      />
                    </RadioGroup>
                  </FormControl>
                );
              },
            },
          ],
          [
            {
              label: "Speaker",
              datatype: data_types.reference,
              reference: "speakers",
              field: "speaker_id",
              onChange: (dataItem) => {
                const speaker_id = dataItem.speaker_id;
                global.UF.dataProvider.get(
                  `speakers`,
                  { id: speaker_id },
                  (speakers) => {
                    if (speakers.length) {
                      const currentSpeaker = speakers[0];
                      dataItem.first_name = currentSpeaker.first_name;
                      dataItem.last_name = currentSpeaker.last_name;
                      dataItem.email = currentSpeaker.email;
                      dataItem.company = currentSpeaker.company;
                      this.setState({ dataItem });
                    }
                  }
                );
              },
            },
            {
              field: "first_name",
              label: "Firstname",
            },
            {
              field: "last_name",
              label: "Lastname",
            },
          ],
          [
            {
              field: "email",
              label: "Email",
            },
            {
              field: "phone",
              label: "Phone",
            },
            {},
          ],
        ],
      };
    }
    this.forceUpdate();
  };

  componentDidMount() {
    this.handleCustomGridManipulation();
    global.UF.dataProvider.datastructure["registrations"].filters = {
      type: ["speaker", "speaker-guest"],
    };
    global.UF.dataProvider.datastructure[
      "registrations"
    ].ignoreValidation?.push("sponsor_id", "media_partner_id");
    global.UF.data_structure["registrations"].fieldsArr
      .find((field) => field.Field === "speaker_id")
      .validators.push(validators.isNull);
    global.UF.data_structure["registrations"].fieldsArr.find(
      (field) => field.Field === "ticket_quantity"
    ).defaultValue = "1";
    global.UF.dataProvider.get(`packages`, { name: "VIP" }, (packages) => {
      if (packages.length) {
        global.UF.data_structure["registrations"].fieldsArr.find(
          (field) => field.Field === "package_id"
        ).defaultValue = packages[0].id;
      } else {
        global.UF.setAlertVisibility(
          true,
          "There is no package with name VIP",
          "warning"
        );
      }
      this.findCurrentPermission();
    });
  }

  handleEmailValidation = async (dataItem) => {
    if (!dataItem) {
      throw new Error("Not valid registration provided!");
    }

    const email = dataItem.email.toLowerCase().trim();

    if (dataItem.isNew) {
      const registration = await global.UF.dataProvider.get_v2(
        `registrations`,
        {
          email: email,
          event_id: global.UF.event_id,
          type: "speaker",
        }
      );

      if (registration.length) {
        throw new Error(
          `Registration email needs to be unique, a registration with email : ${email} already exists!`
        );
      }

      return true;
    }

    return true;
  };

  onSave = async (item, callback) => {
    try {
      await this.handleEmailValidation(item);
      if (item.isNew) {
        item.id = uuid();
        item.Save(() => {
          const body = {
            id: item.id,
            event_id: global.UF.event_id,
          };
          global.UF.makeRequest(
            `POST`,
            `/api/speaker-registration`,
            body,
            true,
            (success) => {
              if (callback) {
                callback(success);
              }
            },
            (error) => {
              console.error(
                "Error occurred when tried to insert Speaker Registration",
                error
              );
              if (callback) {
                callback(error);
              }
            }
          );
        });
      } else {
        item.Save(() => {
          if (callback) {
            callback();
          }
        });
      }
    } catch (err) {
      global.UF.setAlertVisibility(true, err.toString(), "error");
      throw new Error(err);
    }
  };

  onDataItemsLoad = (data, callback) => {
    if (data && data.length) {
      data.forEach((registration, index) => {
        global.UF.dataProvider.get(
          `attendees`,
          {
            registration_id: registration.id,
            status: "approved",
          },
          (attendees) => {
            registration.attendees_completed = attendees.length;
            if (index + 1 === data.length) {
              if (callback) {
                callback();
              }
            }
          }
        );
      });
    } else {
      callback();
    }
  };

  handleResendEmailSpeaker = (callback) => {
    const body = {
      id: this.state.emailItem.id,
      event_id: global.UF.event_id,
    };
    global.UF.makeRequest(
      `POST`,
      `/api/speaker-registration`,
      body,
      true,
      (success) => {
        callback(success);
      },
      (error) => {
        console.error(
          "Error occurred when tried to insert Sponsor Registration",
          error
        );
        callback(error);
      }
    );
  };

  renderResendVIPLinkModal = () => {
    return (
      <ConfirmModalEmail
        open={this.state.emailItem !== null}
        onClose={() => this.setState({ emailItem: null })}
        email={this.state.emailItem ? this.state.emailItem.email : ""}
        onSendEmail={this.handleResendEmailSpeaker}
        text={"Are you sure you want to resend confirmation email to"}
      />
    );
  };

  handleCustomGridManipulation = () => {
    const ds = global.UF.data_structure["registrations"];
    const fields = ds.fieldsArr;
    fields.find((field) => field.Field === "status").onClick = (dataItem) => {
      this.setState({ emailItem: dataItem });
    };
    fields.find((field) => field.Field === "ticket_quantity").renderCell = (
      dataItem
    ) => {
      console.log(this.navigateToAttendees(dataItem));
      return (
        <Tooltip
          title={`${dataItem.value ? dataItem.value : 0} (${
            dataItem.row.attendees_completed
          })`}
        >
          <a
            href={this.navigateToAttendees(dataItem.row)}
            target="_blank"
            style={{ cursor: "pointer", textDecoration: "none" }}
          >
            {dataItem.value ? dataItem.value : 0} (
            {dataItem.row.attendees_completed})
          </a>
        </Tooltip>
      );
    };
  };

  navigateToAttendees = (dataItem) => {
    const registration = dataItem;
    switch (registration.type) {
      case "regular":
        return `${window.location.origin}/events/${global.UF.event_id}/registrations-attendees?registration_id=${registration.id}`;
      case "sponsor":
        return `${window.location.origin}/events/${global.UF.event_id}/sponsor-attendees?registration_id=${registration.id}`;
      case "speaker":
      case "speaker-guest":
        return `${window.location.origin}/events/${global.UF.event_id}/speaker-attendees?registration_id=${registration.id}`;
    }
  };

  handleRowDoubleClick = (params) => {
    navigator.clipboard
      .writeText(
        `${global.UF.EVENT_SETTINGS.EMAILS_SPEAKER_REGISTRATION_REDIRECT_LINK}${params.id}`
      )
      .then(() => {
        global.UF.setAlertVisibility(
          true,
          "Speaker registration link copied to clipboard",
          "info"
        );
      });
  };

  handleBeforeAddNew = (dataItem, callback) => {
    dataItem.type = "speaker";
    dataItem.event_id = this.props.event_id;
    if (callback) {
      callback();
    }
  };

  findCurrentPermission = () => {
    const user =
      typeof localStorage.getItem("user") === "string"
        ? JSON.parse(localStorage.getItem("user"))
        : localStorage.getItem("user");
    const scopes = user.hasOwnProperty("scopes")
      ? typeof user.scopes === "string"
        ? JSON.parse(user.scopes)
        : user.scopes
      : null;
    this.state.scopeCondition =
      scopes.findIndex(
        (permission) =>
          permission.split(":")[0] === this.state.permission &&
          permission.includes("write")
      ) > -1;
    this.setState({ loaded: true });
  };

  render() {
    return (
      <Fragment>
        {this.state.emailItem && this.renderResendVIPLinkModal()}
        <UF_Content
          table="registrations"
          id={this.props.event_id}
          registrationType={["speaker", "speaker-guest"]}
          onDataItemsLoad={this.onDataItemsLoad}
          speakerTypeChange={this.speakerTypeChange}
          title="speaker registration"
          permission={"speaker"}
          formConfig={this.formConfig}
          gridProps={this.gridProps}
          buttons={this.buttons}
          edit={this.state.scopeCondition}
          delete={this.state.scopeCondition}
          export={this.state.scopeCondition}
          add={this.state.scopeCondition}
          sync={this.state.scopeCondition}
          additional_data={this.additional_data}
          onSave={this.onSave}
          beforeAddNew={this.handleBeforeAddNew}
          rowDoubleClick={this.handleRowDoubleClick}
        />
      </Fragment>
    );
  }
}

export default withRouter(RegularRegistration);
