import React, { Fragment } from "react";
import Nx_Search from "./components/nx_search";
import { Box, Button, Menu, Tab, Tabs } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import "../nx_grid.scss";
import "../uf_content.scss";
import Nx_filter_section from "./components/nx_filter_section";

class Nx_Filters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: null,
      anchorEl: null,
      loaded: false,
    };

    this.activeFilters = {};

    this.data_structure = global.UF.data_structure[this.props.table];

    this.filters = this.data_structure.fieldsArr.filter(
      (field) => field.filterable == true
    );
  }

  componentDidMount() {
    if (this.filters && this.filters.length) {
      setTimeout(() => {
        this.filters.map((filter, index) => {
          global.UF.dataProvider.referenceProvider.get(
            filter?.reference,
            (items) => {
              items = items.sort((a, b) => a.label.localeCompare(b.label));
              items = items.filter((item) => item.label && item.label.length);
              filter.items = items;
              if (index + 1 === this.filters.length) {
                this.setState({ loaded: true });
              }
            }
          );
        });
      }, 100);
    } else {
      this.setState({ loaded: true });
    }
  }

  onFilterChange = (filter, value) => {
    if (value == null || value == "") {
      if (this.activeFilters.hasOwnProperty(filter)) {
        delete this.activeFilters[filter];
      }
    } else {
      this.activeFilters[filter] = value;
    }

    if (this.props.onFiltersChange) {
      this.props.onFiltersChange(this.activeFilters);
    }
  };

  renderTabs = () => {
    return (
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={
            this.state.currentTab != null
              ? this.state.currentTab
              : this.filters[0].label
          }
          onChange={(e, tab) => this.setState({ currentTab: tab })}
        >
          {this.filters.map((filter, index) => {
            return (
              <Tab
                className={"tab_text"}
                sx={{ width: `${100 / this.filters.length}%` }}
                label={filter.label}
                value={filter.label}
                key={index}
              />
            );
          })}
        </Tabs>
      </Box>
    );
  };

  renderTabsContent = () => {
    return this.filters.map((filter, index) => {
      return (
        <Nx_filter_section
          onChange={this.onFilterChange}
          key={index}
          hidden={
            (this.state.currentTab != null
              ? this.state.currentTab
              : this.filters[0].label) !== filter.label
          }
          filter={filter}
        />
      );
    });
  };

  renderFilters = () => {
    if (this.filters && this.filters.length) {
      return (
        <div>
          <Fragment>
            <Button
              size="large"
              onClick={(event) =>
                this.setState({ anchorEl: event.currentTarget })
              }
              color="inherit"
              startIcon={<FilterAltIcon />}
              className={"nx_grid_filters header-item"}
            >
              Filter
            </Button>
          </Fragment>
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => this.setState({ anchorEl: null })}
            PaperProps={{
              style: {
                borderRadius: "10",
                background: "#FBFBFC",
                boxShadow:
                  "5px 5px 10px 0px rgba(34, 42, 96, 0.20), -5px -5px 10px 0px rgba(34, 42, 96, 0.07)",
              },
            }}
          >
            {this.renderTabs()}
            {this.renderTabsContent()}
          </Menu>
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    return (
      <Fragment>
        <div className="uf_filters">
          {this.renderFilters()}
          <Nx_Search
            onFilterChange={this.onFilterChange}
            initialSearch={this.props.initialSearch}
            table={this.props.table}
          />
        </div>
      </Fragment>
    );
  }
}

export default Nx_Filters;
