import React, {Fragment} from 'react'
import UF_Content from "../../../../nix_components/components/UF_Content"
import {IconButton} from "@mui/material"
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import {CSVLink} from "react-csv"
import Nx_Loader from "../../../../nix_components/components/nx_loader"

class ExhibitorExport extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            permission: 'export',
            scopeCondition: false,
            data: [],
            filename : ''
        }
        this.csvLink = React.createRef()
    }

    tools = {
        export: () => {
            return (
                <IconButton aria-label="edit" color="default"
                            onClick={this.exportExhibitors}>
                    <PhoneIphoneIcon/>
                </IconButton>
            )
        }
    }

    exportExhibitors = () => {
        global.UF.makeRequest(`GET`, `/api/exports/exhibitors/${global.UF.event_id}`, {},  true, (exhibitors) => {
            this.setState({data: exhibitors?.body, filename : `Exhibitors / ${global.UF.data.currentEvent.title}.csv`}, () => {
                this.csvLink.current.link.click()
            })
        }, (error) => {
            console.error(`Error occurred while trying to fetch exhibitors for export \n Error: ${error}`)
        })
    }

    findCurrentPermission = () => {
        const user = typeof localStorage.getItem('user') === 'string' ? JSON.parse((localStorage.getItem('user'))) : localStorage.getItem('user')
        const scopes = user.hasOwnProperty('scopes') ? typeof user.scopes === 'string' ? JSON.parse(user.scopes) : user.scopes : null
        this.state.scopeCondition = scopes.findIndex(permission => permission.split(':')[0] === this.state.permission && permission.includes('write')) > -1
        this.setState({loaded: true})
    }

    componentDidMount() {
        this.findCurrentPermission()
    }

    gridProps = {
        fields: ['name', 'booth_numbers', 'types', 'category_id', 'published', 'ordering', 'created_at']
    }

    render() {
        return (
            <Fragment>
                <UF_Content table="sponsors" id={this.props.event_id}
                            edit={false}
                            delete={false}
                            add={false}
                            sync={this.state.scopeCondition}
                            tools={this.tools}
                            fetchData={this.getFetchData}
                            title="Sponsors / Exhibitors" formConfig={this.formConfig} gridProps={this.gridProps}
                            beforeAddNew={this.handleBeforeAddNew}/>
                <div style={{display: 'none'}}>
                    <CSVLink data={this.state.data} ref={this.csvLink} filename={this.state.filename}/>
                </div>
            </Fragment>
        )
    }


}

export default ExhibitorExport