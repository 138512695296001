const referenceTypes = {
  local: "local",
  remote: "remote",
};

const attendeeStatuses = {
  pending: "pending",
  rejected: "rejected",
  approved: "approved",
};

const attendeeActive = {
  yes: "yes",
  no: "no",
};

const countries = {
  AF: "Afghanistan",
  AX: "Aland Islands",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua and Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  PW: "Belau",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia",
  BQ: "Bonaire, Saint Eustatius and Saba",
  BA: "Bosnia and Herzegovina",
  BW: "Botswana",
  BV: "Bouvet Island",
  BR: "Brazil",
  IO: "British Indian Ocean Territory",
  VG: "British Virgin Islands",
  BN: "Brunei",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  CV: "Cape Verde",
  KY: "Cayman Islands",
  CF: "Central African Republic",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CC: "Cocos (Keeling) Islands",
  CO: "Colombia",
  KM: "Comoros",
  CG: "Congo (Brazzaville)",
  CD: "Congo (Kinshasa)",
  CK: "Cook Islands",
  CR: "Costa Rica",
  HR: "Croatia",
  CU: "Cuba",
  CW: "Curaçao",
  CY: "Cyprus",
  CZ: "Czech Republic",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  ET: "Ethiopia",
  FK: "Falkland Islands",
  FO: "Faroe Islands",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  PF: "French Polynesia",
  TF: "French Southern Territories",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard Island and McDonald Islands",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle of Man",
  IL: "Israel",
  IT: "Italy",
  CI: "Ivory Coast",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  XK: "Kosovo",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Laos",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macao S.A.R., China",
  MK: "Macedonia",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  FM: "Micronesia",
  MD: "Moldova",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands",
  NC: "New Caledonia",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  KP: "North Korea",
  MP: "Northern Mariana Islands",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PS: "Palestinian Territory",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RE: "Reunion",
  RO: "Romania",
  RU: "Russia",
  RW: "Rwanda",
  ST: "São Tomé and Príncipe",
  BL: "Saint Barthélemy",
  SH: "Saint Helena",
  KN: "Saint Kitts and Nevis",
  LC: "Saint Lucia",
  SX: "Saint Martin (Dutch part)",
  MF: "Saint Martin (French part)",
  PM: "Saint Pierre and Miquelon",
  VC: "Saint Vincent and the Grenadines",
  WS: "Samoa",
  SM: "San Marino",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  GS: "South Georgia/Sandwich Islands",
  KR: "South Korea",
  SS: "South Sudan",
  ES: "Spain",
  LK: "Sri Lanka",
  SD: "Sudan",
  SR: "Suriname",
  SJ: "Svalbard and Jan Mayen",
  SZ: "Swaziland",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syria",
  TW: "Taiwan",
  TJ: "Tajikistan",
  TZ: "Tanzania",
  TH: "Thailand",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad and Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks and Caicos Islands",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates",
  GB: "United Kingdom (UK)",
  US: "United States (US)",
  UM: "United States (US) Minor Outlying Islands",
  VI: "United States (US) Virgin Islands",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VA: "Vatican",
  VE: "Venezuela",
  VN: "Vietnam",
  WF: "Wallis and Futuna",
  EH: "Western Sahara",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe",
};

const awardStatuses = {
  pre_voting: "pre-voting",
  voting: "voting",
  pre_winning: "pre-winning",
  nomination: "nomination",
  winning: "winning",
};

const nomineeStatuses = {
  approved: "approved",
  pending: "pending",
  rejected: "rejected",
};

const registrationTypes = {
  regular: "regular",
  sponsor: "sponsor",
  speaker: "speaker",
  media_partner: "media-partner",
  speaker_guest: "speaker-guest",
};

const registrationStatuses = {
  pending_confirmation: "Pending confirmation",
  pending_details: "Pending details",
  under_review: "Under review",
  rejected: "Rejected",
  per_payment: "Pending payment",
  completed: "Completed",
};

const referenceConfigs = {
  users: {
    id: "id",
    label: "email",
    type: referenceTypes.remote,
    table: "users",
    filters: {
      event_id: null,
    },
  },
  schedule_types: {
    id: "name",
    label: "name",
    type: referenceTypes.remote,
    table: "schedule_types",
    filters: {
      event_id: null,
    },
  },
  sponsors: {
    id: "id",
    label: "name",
    type: referenceTypes.remote,
    table: "sponsors",
    filters: {
      event_id: null,
    },
  },
  media_partners: {
    id: "id",
    label: "name",
    type: referenceTypes.remote,
    table: "media_partners",
    filters: {
      event_id: null,
    },
  },
  events: {
    id: "id",
    type: referenceTypes.remote,
    table: "events",
    label: "name",
  },
  awards: {
    id: "id",
    type: referenceTypes.remote,
    table: "awards",
    label: ["name"],
  },
  registrations: {
    id: "id",
    type: referenceTypes.remote,
    table: "registrations",
    label: ["id"],
    filters: {
      event_id: null,
    },
  },
  speakers: {
    id: "id",
    type: referenceTypes.remote,
    table: "speakers",
    label: (dataItem) => {
      return `${dataItem.first_name} ${dataItem.last_name}, ${dataItem.company}`;
    },
    filters: {
      event_id: null,
      published: 1,
    },
  },
  registration_categories: {
    id: "id",
    type: referenceTypes.remote,
    table: "registration_categories",
    label: ["name"],
    filters: {
      event_id: null,
    },
  },
  categories: {
    id: "id",
    type: referenceTypes.remote,
    table: "categories",
    label: ["name"],
    filters: {
      award_id: null,
    },
  },
  nominees: {
    id: "id",
    type: referenceTypes.remote,
    table: "nominees",
    label: "name",
    filters: {
      award_id: null,
    },
  },
  nominations: {
    id: "id",
    label: "name",
    type: referenceTypes.remote,
    table: `nominations`,
    filters: {
      award_id: null,
    },
  },
  votes: {
    id: "id",
    type: referenceTypes.remote,
    table: "votes",
    label: "email",
    filters: {
      award_id: null,
    },
  },
  winners: {
    id: "id",
    type: referenceTypes.remote,
    table: "winners",
    label: "nominee_id",
    filters: {
      award_id: null,
    },
  },
  packages: {
    id: "id",
    label: "name",
    type: referenceTypes.remote,
    table: "packages",
    filters: {
      event_id: null,
    },
  },
  package_categories: {
    id: "id",
    label: "name",
    type: referenceTypes.remote,
    table: "package_categories",
    filters: {
      event_id: null,
    },
    groupBy: "package_id",
  },
  package_subcategories: {
    id: "id",
    label: "name",
    type: referenceTypes.remote,
    table: "package_subcategories",
    filters: {
      event_id: null,
    },
    groupBy: "package_category_id",
  },
  promo_codes: {
    id: "id",
    label: "code",
    type: referenceTypes.remote,
    table: "promo_codes",
    filters: {
      event_id: null,
    },
  },
  sponsor_types: {
    id: "id",
    label: "name",
    type: referenceTypes.remote,
    table: "sponsor_types",
    filters: {
      event_id: null,
    },
  },
  sponsor_categories: {
    id: "id",
    label: "name",
    type: referenceTypes.remote,
    table: "sponsor_categories",
    filters: {
      event_id: null,
    },
  },
  countries: {
    type: referenceTypes.local,
    source: countries,
  },
  attendeesStatuses: {
    type: referenceTypes.local,
    source: attendeeStatuses,
  },
  awardsStatuses: {
    type: referenceTypes.local,
    source: awardStatuses,
  },
  nomineesStatuses: {
    type: referenceTypes.local,
    source: nomineeStatuses,
  },
  registrationTypes: {
    type: referenceTypes.local,
    source: registrationTypes,
  },
  registrationStatuses: {
    type: referenceTypes.local,
    source: registrationStatuses,
  },
  attendeeActive: {
    type: referenceTypes.local,
    source: attendeeActive,
  },
};

class DataReferences {
  references = {};
  reference_configs = referenceConfigs;
  data_provider = null;

  constructor(data_provider) {
    this.data_provider = data_provider;

    Object.keys(referenceConfigs).forEach((key) => {
      if (referenceConfigs[key].type == referenceTypes.local) {
        this.references[key] = [];
        if (key === "countries") {
          Object.keys(referenceConfigs[key].source).forEach((item) => {
            this.references[key].push({
              id: item,
              label: referenceConfigs[key].source[item],
            });
          });
        } else {
          Object.keys(referenceConfigs[key].source).forEach((item) => {
            this.references[key].push({
              id: referenceConfigs[key].source[item],
              label: referenceConfigs[key].source[item],
            });
          });
        }
      }
    });
  }

  search = (reference, search) => {
    const data = this.references[reference];
    if (data) {
      let tempData = data.map((ref) => {
        if (ref.label.toLowerCase().includes(search.toLowerCase())) {
          return `'${ref.id.toString()}'`;
        } else {
          return null;
        }
      });
      tempData = tempData.filter((ref) => ref !== null);
      return tempData;
    }
  };

  getReferenceLabel = (reference, value) => {
    let label = "";
    if (reference !== null && this.references[reference] && value !== null) {
      label = this.references[reference].find((ref) => ref.id == value)
        ? this.references[reference].find((ref) => ref.id == value).label
        : "";
    }
    return label;
  };

  get = (reference, callBack, force = false) => {
    if (
      referenceConfigs[reference] &&
      referenceConfigs[reference].hasOwnProperty("filters") &&
      referenceConfigs[reference].filters.hasOwnProperty("event_id") &&
      referenceConfigs[reference].filters.event_id != global.UF.event_id
    ) {
      referenceConfigs[reference].filters.event_id = global.UF.event_id;
      this.references[reference] = null;
    }
    if (
      referenceConfigs[reference] &&
      referenceConfigs[reference].hasOwnProperty("filters") &&
      referenceConfigs[reference].filters.hasOwnProperty("award_id") &&
      referenceConfigs[reference].filters.award_id != global.UF.award_id
    ) {
      referenceConfigs[reference].filters.award_id = global.UF.award_id;
      this.references[reference] = null;
    }

    if (this.references[reference] && force == false) {
      setTimeout(() => {
        callBack(this.references[reference]);
      }, 100);
    } else {
      let filters = referenceConfigs[reference].filters
        ? referenceConfigs[reference].filters
        : {};
      this.data_provider.get(reference, filters, (data) => {
        this.references[reference] = data.map((item) => {
          const newItem = {
            id: item[referenceConfigs[reference].id],
            label:
              typeof referenceConfigs[reference].label === "function"
                ? referenceConfigs[reference].label(item)
                : item[referenceConfigs[reference].label],
          };

          if (referenceConfigs[reference].hasOwnProperty("groupBy")) {
            if (
              referenceConfigs[reference].hasOwnProperty("groupByException")
            ) {
              if (
                !referenceConfigs[reference][`groupByException`].includes(
                  newItem.label
                )
              ) {
                newItem.group = item[referenceConfigs[reference].groupBy];
              }
            } else {
              newItem.group = item[referenceConfigs[reference].groupBy];
            }
          }
          return newItem;
        });
        callBack(this.references[reference]);
      });
    }
  };
}

export default DataReferences;
