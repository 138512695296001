import data_types from "./data_types"

class DataItem {

    #db_record = null
    #cur_record = null

    data_provider = null

    table_structure = null
    isDeleted = false
    isNew = false
    isDirty = false
    isValid = true

    constructor(data_provider, table_structure, db_record = null) {
        this.data_provider = data_provider
        this.table_structure = table_structure
        this.#db_record = db_record

        if (this.#db_record == null) {
            this.createNew()
        }

        this.Reset()


        this.table_structure.fieldsArr.forEach(field => {
            Object.defineProperty(this, field.Field, {
                get() {
                    return this.#cur_record[field.Field]
                },

                set(value) {
                    this.#cur_record[field.Field] = value
                    const cf = this.GetChangedFields()
                    this.isDirty = Object.keys(cf).length > 0

                },
            })
        })
    }

    parseValueIntoDatatype = (record, field) => {
        let value = record[field.field]
        if (record[field.field] !== null) {
            if (field.datatype == data_types.datetime || field.datatype == data_types.time) {
                value = new Date(record[field.field])

                if (field.timezone) {
                    if (field.timezone === 'UTC') {
                        value.setMinutes(value.getMinutes() + (value.getTimezoneOffset()))
                    }

                }
            }
        }

        return value
    }

    Reset = () => {
        this.#cur_record = JSON.parse(JSON.stringify(this.#db_record))


        this.table_structure.fieldsArr.forEach(field => {
            this.#cur_record[field.field] = this.parseValueIntoDatatype(this.#cur_record, field)
        })
        this.isDirty = false
    }

    createNew = () => {
        this.isNew = true
        this.#db_record = {}
        this.table_structure.fieldsArr.forEach(field => {
            if (field.hasOwnProperty('defaultValue')) {
                this.#db_record[field.name] = field.defaultValue
            } else {
                this.#db_record[field.name] = null
            }
        })
    }

    Validate = () => {
        return this.table_structure.validate(this)
    }

    Save = (callback) => {
        this.data_provider.save(this.table_structure.TableName, this, callback)
    }

    Delete = (callback) => {
        this.data_provider.delete(this.table_structure.TableName.toLowerCase(), this, callback)
    }

    Refresh = (callback) => {
        this.data_provider.get(this.table_structure.TableName.toLowerCase(), {ID: this.#cur_record.ID}, (row, total = 0) => {
            this.#db_record = row[0]
            this.Reset()
            callback(this.#db_record)
        }, false)
    }

    GetChangedFields = () => {
        const changedFields = {}
        this.table_structure.fieldsArr.forEach(tmpField => {
            const field = tmpField.Field
            if ((this.isNew && this.#cur_record[field] != null) || (!this.isNew && this.#cur_record[field] != this.#db_record[field])) {
                if (tmpField.datatype == data_types.datetime || tmpField.datatype == data_types.time) {
                    if(this.#cur_record[field] != null) {
                        let val = new Date(this.#cur_record[field].getTime())
                        if(this.isNew) {
                            if (tmpField.timezone) {
                                if (tmpField.timezone == 'UTC') {
                                    val.setMinutes(val.getMinutes() - val.getTimezoneOffset())
                                }
                            }
                            changedFields[field] = val.toISOString().substring(0, 23)
                        }
                        else {
                            if(this.#db_record[field] != null) {
                                if (this.#cur_record[field].getTime() != this.parseValueIntoDatatype(this.#db_record, tmpField)?.getTime()) {
                                    if (tmpField.timezone) {
                                        if (tmpField.timezone == 'UTC') {
                                            val.setMinutes(val.getMinutes() - val.getTimezoneOffset())
                                        }
                                    }
                                    changedFields[field] = val.toISOString().substring(0, 23)
                                }
                            }
                            else {
                                if (tmpField.timezone) {
                                    if (tmpField.timezone == 'UTC') {
                                        val.setMinutes(val.getMinutes() - val.getTimezoneOffset())
                                    }
                                }
                                changedFields[field] = val.toISOString().substring(0, 23)
                            }
                        }
                    }
                    else {
                        changedFields[field] = null;
                    }
                } else {
                    changedFields[field] = this.#cur_record[field]

                    if (tmpField.Type === 'tinyint(1)') {
                        changedFields[field] = changedFields[field] ? 1 : 0
                    }
                    if (tmpField.Type === 'json') {
                        changedFields[field] = JSON.stringify(changedFields[field])
                    }
                }
            }
        })
        return changedFields
    }
}

export default DataItem
