import * as React from 'react'
import {Draggable} from 'react-beautiful-dnd'
import {ListItem} from '@mui/material'
import {Fragment} from "react"


const UF_DraggableListItem = ({item, index, table}) => {

    const renderSponsorTypes = (item) => {
          return (
              item.hasOwnProperty('types_labels')
                  ?
                  item.types_labels.map((type, index) => {
                      return (
                          <span key={index}>{type}{index + 1 < item.types_labels.length ? ', ' : ''}</span>
                      )
                  })
                  : null
          )
    }


    const renderBoothNumbers = (booths) => {
        if(booths && typeof booths == 'string' && booths.length) {
            booths = JSON.parse(booths);
            if (booths === null) {
                booths = [];
            }
            return booths.join(', ')
        }
        else {
            return ''
        }
    }

    const renderData = () => {
        if (table === 'sponsors') {
            return (
                <Fragment>
                    <span style={{width : '200px', textAlign : 'center'}}>
                        {item.name}</span>
                    <span style={{width : '200px', textAlign : 'center'}}>
                        {renderSponsorTypes(item)}
                    </span>
                    <span style={{width : '200px', textAlign : 'center'}}>
                        {renderBoothNumbers(item.booth_numbers)}
                    </span>
                    <span style={{width : '200px', textAlign : 'center'}}>{item.ordering}</span>
                </Fragment>
            )
        }
        if(table === 'media_partners') {
            return (
                <Fragment>
                     <span style={{width : '200px', textAlign : 'center'}}>
                        {item.name}
                        </span>
                    <span style={{width : '200px', textAlign : 'center'}}>
                        {item.ordering}
                        </span>
                </Fragment>
            )
        }
        if(table === 'agenda_topics') {
            return (
                <Fragment>
                     <span style={{width : '200px', textAlign : 'center'}}>
                        {item.title}
                        </span>
                    <span style={{width : '200px', textAlign : 'center'}}>
                        {item.ordering}
                        </span>
                </Fragment>
            )
        }
        if(table === 'speakers') {
            return (
                <Fragment>
                     <span style={{width : '200px', textAlign : 'center'}}>
                        {item.first_name} {item.last_name}
                        </span>
                    <span style={{width : '200px', textAlign : 'center'}}>
                        {item.ordering}
                        </span>
                </Fragment>
            )
        }
    }

    return (
        <Fragment>
            <Draggable draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                    <ListItem
                        ref={provided.innerRef}
                        sx={{
                            borderBottom: "1px solid gray",
                            background: `${snapshot.isDragging ? 'rgb(235,235,235)' : '#fff'}`,
                            height: '60px',
                            display: 'flex',
                            width: '650px',
                            justifyContent: 'space-between'
                        }}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        {renderData()}
                    </ListItem>
                )}
            </Draggable>
        </Fragment>
    )
}

export default UF_DraggableListItem
