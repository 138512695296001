import React from "react";
import Button from "@mui/material/Button";
import { Tooltip } from "@mui/material";
import * as Filesaver from "file-saver";
import XLXS from "sheetjs-style";

const ExcelExport = ({excelData, fileName, buttonName}) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    
    const exportToExcel = async () => {
        const writerStream = XLXS.utils.json_to_sheet(excelData);
        const writerBuffer = { Sheets: { "data": writerStream }, SheetNames: ["data"] };
        const excelBuffer = XLXS.write(writerBuffer, { "bookType": "xlsx", "type": "array" });
        const data = new Blob([excelBuffer], {"type": fileType});
        Filesaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <>
            <Tooltip title="Export to XLXS">
                <Button 
                    variant="contained"
                    onClick={(e) => exportToExcel(fileName)}
                    color="primary"
                    style={{cursor: "pointer"}}
                >{buttonName}
                </Button>
            </Tooltip>
        </>
    )
    
}

export default ExcelExport