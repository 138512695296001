import React from "react";
import UF_Content from "../../../../nix_components/components/UF_Content";

class Accounts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            permission: "award",
            scopeCondition: false,
            loaded: false,
        }
    }

    buttons = {};

    formConfig = {
        rows: []
    }

    findCurrentPermission = () => {
        const user =
          typeof localStorage.getItem("user") === "string"
            ? JSON.parse(localStorage.getItem("user"))
            : localStorage.getItem("user");
        const scopes = user.hasOwnProperty("scopes")
          ? typeof user.scopes === "string"
            ? JSON.parse(user.scopes)
            : user.scopes
          : null;
        this.state.scopeCondition =
          scopes.findIndex(
            (permission) =>
              permission.split(":")[0] === this.state.permission &&
              permission.includes("write")
          ) > -1;
        this.setState({ loaded: true });
      };

      componentDidMount() {
        this.findCurrentPermission();
      }

      render() {
        return (
          <UF_Content
            table="accounts"
            title="Accounts"
            id={this.props.award_id}
            permission="award"
            buttons={this.buttons}
            edit={this.state.scopeCondition}
            delete={this.state.scopeCondition}
            add={this.state.scopeCondition}
            export={this.state.scopeCondition}
            sync={this.state.scopeCondition}
            formConfig={this.formConfig}
            beforeAddNew={this.handleBeforeAddNew}
          />
        );
      }




}

export default Accounts;