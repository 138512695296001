import React, {Fragment} from 'react'
import UF_Content from "../../../../nix_components/components/UF_Content"
import Nx_Loader from "../../../../nix_components/components/nx_loader"

class Lunch extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            permission : 'lunch',
            scopeCondition : false,
            loaded : false
        }
    }

    buttons = {}


    // gridProps = {
    //     fields: [ 'id', 'lunch_voucher', 'scan_date']
    // }

    handleBeforeAddNew = (dataItem, callback) => {
        dataItem.event_id = global.UF.event_id
        if(callback) {
            callback()
        }
    }

    findCurrentPermission = () => {
        const user = typeof localStorage.getItem('user') === 'string' ? JSON.parse((localStorage.getItem('user'))) : localStorage.getItem('user')
        const scopes = user.hasOwnProperty('scopes') ? typeof user.scopes === 'string' ? JSON.parse(user.scopes) : user.scopes : null;
        this.state.scopeCondition = scopes.findIndex(permission => permission.split(':')[0] === this.state.permission && permission.includes('write')) > -1;
        this.setState({loaded : true})
    }

    componentDidMount() {
        this.findCurrentPermission();
    }


    render() {
        return (
            <UF_Content table="lunch_voucher_scans" id={this.props.event_id} permission={'lunch'}
                        add={false}
                        sync={this.state.scopeCondition}
                        title="Lunch" gridProps={this.gridProps}
                        edit={this.state.scopeCondition}
                        delete={this.state.scopeCondition}
                        buttons={this.buttons}
                        beforeAddNew={this.handleBeforeAddNew}
            />
        )
    }
}

export default Lunch