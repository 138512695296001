import React, { Fragment } from "react";
import Secure from "./app/Screens/secure";
import Public from "./app/Screens/public";
import UF from "./app/nix_components/utils/UF";
import DataProvider from "./app/nix_components/data/DataProvider";
import Nx_Loader from "./app/nix_components/components/nx_loader";
import Nx_Snackbar from "./app/nix_components/ui_components/alerts/nx_snackbar";

class App extends React.Component {
  constructor(props) {
    super(props);
    global.UF = new UF();
    global.UF.data = {
      events: null,
      currentEvent: null,
      awards: null,
      currentAward: null,
    };
    global.UF.dataProvider = new DataProvider();

    this.state = {
      authenticated:
        global.localStorage.getItem("token") !== undefined &&
        global.localStorage.getItem("token") !== null,
      user: null,
      initializing: true,
    };
  }

  login = (loginObj) => {
    try {
      let user = JSON.parse(loginObj);
      global.UF.EVENT_SETTINGS = user.event_settings;
      delete user.event_settings;
      if (user.hasOwnProperty("access_token")) {
        localStorage.setItem("token", user.access_token);
      }
      if (
        user.hasOwnProperty("access_token") &&
        user.hasOwnProperty("scopes") &&
        user.hasOwnProperty("id") &&
        user.hasOwnProperty("email")
      ) {
        localStorage.setItem("user", loginObj);
        this.setState({ user: user, authenticated: true });
      }
    } catch {
      this.setState({ user: null, authenticated: false });
    }
  };

  logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("event_settings");
    window.location.href = "/";
  };

  setUser = () => {
    const user =
      localStorage.getItem("user") != null &&
      typeof JSON.parse(localStorage.getItem("user")) === "object"
        ? JSON.parse(localStorage.getItem("user"))
        : null;
    if (user != null) {
      if (
        user.hasOwnProperty("scopes") &&
        user.hasOwnProperty("access_token") &&
        user.hasOwnProperty("id") &&
        user.hasOwnProperty("email")
      ) {
        this.state.authenticated = true;
        this.state.user = user;
        this.forceUpdate();
      } else {
        this.state.authenticated = false;
        this.state.user = null;
        this.forceUpdate();
      }
    } else {
      this.state.authenticated = false;
      this.state.user = null;
      this.forceUpdate();
    }
  };

  findImage = () => {
    const url = window.location.href;
    if (url.includes("realty")) {
      return "realtyon.png";
    } else if (url.includes("financemagnates")) {
      return "financemagnates.png";
    } else if (url.includes("ultimatefintech")) {
      return "ifxexpo.png";
    } else {
      return "ifxexpo.png";
    }
  };

  componentDidMount = async () => {
    const link = document.querySelector("link[rel*='icon']");
    if (link) {
      link.href = `../../assets/images/${this.findImage()}`;
    }
    try {
      this.setUser();
      await this.checkIfScopesHasChanged();
      document.title = "Backoffice";
      this.setState({ initializing: false });
    } catch (err) {
      console.error(`Error : ${err}`);
    }
  };

  checkIfScopesHasChanged = async () => {
    try {
      if (this.state.user) {
        const users = await global.UF.dataProvider.get_v2(`users`, {
          id: this.state.user.id,
        });
        if (users.length) {
          if (users[0].scopes !== this.state.user.scopes) {
            this.logout();
          }
        } else {
          this.logout();
        }
      }
    } catch (err) {
      console.error(`Error`, err);
      return err;
    }
  };

  renderApp = () => {
    if (this.state.authenticated) {
      return <Secure user={this.state.user} />;
    } else {
      return <Public onLogin={this.login} />;
    }
  };

  render() {
    if (this.state.initializing) {
      return <Nx_Loader show={true} />;
    } else {
      return (
        <Fragment>
          <Nx_Snackbar />
          <Nx_Loader />
          {this.renderApp()}
        </Fragment>
      );
    }
  }
}

export default App;
