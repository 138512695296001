import React from 'react'
import {Route, Routes} from "react-router-dom";
import Nominations from "./Nominations/Nominations"
import Categories from "./Categories/Categories";
import Nominees from "./Nominees/Nominees";
import CurrentNominees from './CurrentNominees/CurrentNominees';
import Votes from "./Votes/Votes";
import Winners from './Winners/Winners';
import Accounts from './Accounts/Accounts';
import withRouter from "../../../nix_components/components/withRouter";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CategoryIcon from "@mui/icons-material/Category"
import PeopleIcon from "@mui/icons-material/People"
import HowToVoteIcon from '@mui/icons-material/HowToVote';

class Awards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            award_id: props.router.params.award_id,
            loaded : false
        };
    }

    generateMenuItems = () => {
        this.prefix = '../awards/' + this.state.award_id;
        return (
            this.menuItems = [
                {
                    title: 'Nominations',
                    level: 1,
                    url: this.prefix + '/award',
                    scope : 'award',
                    icon: <EmojiEventsIcon/>,
                    element: () => {return <Nominations award_id={this.state.award_id} fetchMenuItems={this.props.fetchMenuItems}/>}
                },
                {
                    title: 'Categories',
                    level: 1,
                    url: this.prefix +'/categories',
                    icon: <CategoryIcon/>,
                    scope : 'award',
                    element: () => {return <Categories award_id={this.state.award_id}/>}
                },
                {
                    title: 'Nominees',
                    level: 1,
                    url: this.prefix +'/nominees',
                    icon: <PeopleIcon/>,
                    scope : 'award',
                    element: () => {return <Nominees award_id={this.state.award_id}/>}
                },
                {
                    title: 'Current nominations',
                    level: 1,
                    url: this.prefix +'/current-nominees',
                    icon: <PeopleIcon/>,
                    scope : 'award',
                    element: () => {return <CurrentNominees award_id={this.state.award_id}/>}
                },
                {
                    title: 'Votes',
                    level: 1,
                    url: this.prefix + '/votes',
                    icon : <HowToVoteIcon/>,
                    scope : 'awards-admin',
                    element: () => {return <Votes award_id={this.state.award_id}/>}
                },
                {
                    title: 'Winners',
                    level: 1,
                    url: this.prefix + '/winners',
                    icon : <HowToVoteIcon/>,
                    scope : 'awards-admin',
                    element: () => {return <Winners award_id={this.state.award_id}/>}
                },
                {
                    title: 'Accounts',
                    level: 1,
                    url: this.prefix + '/accounts',
                    icon : <PeopleIcon/>,
                    scope : 'awards-admin',
                    element: () => {return <Accounts award_id={this.state.award_id}/>}
                },
            ]
        )
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(this.props.router.params.award_id != nextProps.router.params.award_id) {
            this.state.award_id = nextProps.router.params.award_id;
            this.props.getMenuItems(this.generateMenuItems())
        }
        return true
    }

    renderRoutes = () => {
        if(this.props.menuItems && this.props.menuItems.length) {
            return (
                this.props.menuItems.map((menuItem, index) => {
                    if(menuItem.hasOwnProperty('write') || menuItem.hasOwnProperty('read')) {
                        if(menuItem.write || menuItem.read) {
                            if(menuItem.hasOwnProperty('children')) {
                                return (
                                    menuItem.children.map((child, key) => {
                                        let currentMenuItem = menuItem.children.find(item => item.url === child.url)
                                        return (
                                            <Route path={`/${currentMenuItem.url.split('/')[3]}`} key={key} element={currentMenuItem.element()}/>
                                        )
                                    })
                                )
                            }
                            else {
                                let currentMenuItem = this.props.menuItems.find(item => item.url === menuItem.url)
                                return (
                                    <Route path={`/${currentMenuItem.url.split('/')[3]}`} key={index} element={currentMenuItem.element()}/>
                                )
                            }
                        }
                        else return null
                    }
                    else return null
                })
            )
        }
        else return null
    }

    componentDidMount() {
        this.props.getMenuItems(this.generateMenuItems())
        this.state.loaded = true;
        this.forceUpdate()
    }

    render() {
            this.routes = this.renderRoutes()
        return (
                <Routes>
                    {this.routes}
                    <Route path="/*" element={<Nominations award_id={this.state.award_id} fetchMenuItems={this.props.fetchMenuItems}/>}/>
                </Routes>
        );
    }
}
export default withRouter(Awards)