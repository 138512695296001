import React, { Fragment } from "react";
import UF_Content from "../../../../nix_components/components/UF_Content";
import data_types from "../../../../nix_components/data/data_types";
import moment from "moment";
import uuid from "react-uuid";

class Languages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: "event",
      scopeCondition: false,
      loaded: false,
    };
  }

  buttons = {
  };

  formConfig = {
    rows: [
        [
          {
            field: "name",
            label: "Language name",
            datatype: data_types.string,
          },
          {
            field: "short_name",
            label: "Language short name",
            datatype: data_types.string,
          },
        ],
      ],
  };

  findCurrentPermission = () => {
    const user =
      typeof localStorage.getItem("user") === "string"
        ? JSON.parse(localStorage.getItem("user"))
        : localStorage.getItem("user");
    const scopes = user.hasOwnProperty("scopes")
      ? typeof user.scopes === "string"
        ? JSON.parse(user.scopes)
        : user.scopes
      : null;
    this.state.scopeCondition =
      scopes.findIndex(
        (permission) =>
          permission.split(":")[0] === this.state.permission &&
          permission.includes("write")
      ) > -1;
    this.setState({ loaded: true });
  };

  componentDidMount() {
    this.findCurrentPermission();
  }

  render() {
    return (
      <Fragment>
        {this.state.settingsItem && this.renderSetSettingsModal()}
        <UF_Content
          table="languages"
          title="Languages"
          permission="event"
          buttons={this.buttons}
          edit={this.state.scopeCondition}
          export={this.state.scopeCondition}
          delete={this.state.scopeCondition}
          add={this.state.scopeCondition}
          sync={this.state.scopeCondition}
          formConfig={this.formConfig}
        />
      </Fragment>
    );
  }
}

export default Languages;
