import React from 'react';
import withRouter from '../../../../nix_components/components/withRouter';

class Winners extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            permission: 'awards-admin',
            scopeCondition: false,
            loaded: false,
            nominations: [],
            categories: [],
            nominees: [],
            winners: [],
            nominationsWithNominees: [],
            winnersForExport: [],
        }
    }
    findCurrentPermission = () => {
        const user = typeof localStorage.getItem('user') === 'string' ? JSON.parse((localStorage.getItem('user'))) : localStorage.getItem('user');
        const scopes = user.hasOwnProperty('scopes') ? typeof user.scopes === 'string' ? JSON.parse(user.scopes) : user.scopes : null;
        this.state.scopeCondition = scopes.findIndex(permission => permission.split(':')[0] === this.state.permission && permission.includes('write')) > -1;
        this.setState({loaded : true});
    }
    loadCategories = async () => {
        await global.UF.dataProvider.get(`categories`, {award_id: global.UF.award_id}, (categories) => {
            this.setState({categories: categories})
        })
    }
    loadNominations = async () => {
        await global.UF.dataProvider.get(`nominations`, {award_id: global.UF.award_id}, (nominations) => {
            this.setState({nominations: nominations})
        });
    }
    loadNominees = async () => {
        await global.UF.dataProvider.get(`nominees`, {award_id: global.UF.award_id}, (nominees) => {
            this.setState({nominees: nominees})
        })
    }
    loadWinners = async () => {
        await global.UF.dataProvider.get(`winners`, {award_id: global.UF.award_id}, (winners) => {
            this.setState({winners: winners})
        })
    }

    createNominationsWithNominees = () => {
        if (this.state.nominations.length && this.state.nominees.length) {
            let nominations = this.state.nominations
            let nominees = this.state.nominees
            let categories = this.state.categories
            const winners = this.state.winners;
            let nominationsWithNominees = []
            nominations.forEach( async (nomination) => {
                let cat = categories.filter((category) => category.id === nomination.category_id)
                const winner = winners.filter((winner) => winner.nomination_id === nomination.id);
                let nominationWithNominee = {
                    id: nomination.id,
                    name: nomination.name,
                    category: cat[0].name,
                    winner: winner.length ? winner[0].nominee_id : null,
                    nominees: []
                }
                nominees.forEach((nominee) => {
                    const nominationIds = JSON.parse(nominee.nomination_id)
                    if (nominationIds && nominationIds.length) {
                        nominationIds.forEach((nominationId) => {
                            if (nomination.id === nominationId) {
                                let nomineeForNomination = {
                                    name: nominee.name,
                                    id: nominee.id
                                }

                                nominationWithNominee.nominees.push(nomineeForNomination)
                                nominationWithNominee.nomineesCount = nominationWithNominee.nomineesCount + 1
                            }
                        })
                    }
                })

                nominationsWithNominees.push(nominationWithNominee)
            })
            
            this.setState({nominationsWithNominees: nominationsWithNominees})
        }
    }

    loadWinnerForExport = () => {

    }

    saveWinner = async (value) => {
        const winner = {
            nominee_id: value.target.value,
            nomination_id: value.target.id,
            award_id: this.props.award_id
        }
        await global.UF.makeRequest_v2(`POST`, `/api/awards/save-winners`, winner, true);
    }

    componentDidMount() {
        this.findCurrentPermission();
        this.loadCategories();
        this.loadNominations();
        this.loadNominees();
        this.loadWinners();
        setTimeout(() => {
            this.createNominationsWithNominees();
        }, 1000);
    }

    render() {
        return (
            <div style={{height: '600px', overflowY: 'auto'}}>
                <h2 style={{textAlign: 'center'}}>Winners</h2>
                <div>
                    {/* <ExcelExport excelData={this.state.votesForExport} fileName={"votes"} buttonName={"Export winners"}/> */}
                </div>
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    {this.state.nominationsWithNominees.map((nomination, index) => {
                        return (
                            <div key={index} style={{flex: '1', flexBasis: '20%', border: '1px solid black', padding: '10px', textAlign: 'center'}}>
                                <h3 style={{textAlign: 'center'}}>{nomination.name} ({nomination.category})</h3>
                                <select onChange={this.saveWinner} id={nomination.id} style={{width: '200px'}}>
                                    <option value={null} disabled selected>Choose winner</option>
                                    {nomination.nominees.map((nominee, index) => {
                                        return (
                                            <option id={nomination.id} value={nominee.id} key={index} selected={nomination.winner === nominee.id ? 'selected' : null}>{nominee.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
}

export default withRouter(Winners)