import React, { Fragment } from 'react'
import { Chart, registerables } from 'chart.js'
import { Line } from 'react-chartjs-2'
import "./scss/statistics.scss"

Chart.register(...registerables)

class OnSpotOne extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      permission: 'statistics',
      eventName: 'IFX INTERNATIONAL Cyprus 2024',
      eventDate: '21-09-2023',
      totalData: {
        registered: 156,
        printed: 391,
      },
    }

    this.server1 = {
      labels: [
        '9:00', '10:00', '11:00', '12:00', '13:00'
      ],
      datasets: [
        {
          label: 'Printed',
          backgroundColor: [
            'Orange',
          ],
          fill: false,
          lineTension: 0.5,
          borderColor: 'rgba(0,0,0,1)',
          borderWidth: 2,
          data: [17, 22, 36, 21, 22],
        },
      ],
    }

    this.server1Options = {
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Server 1 Printed by hour',
        },
      },
    }

    this.server2 = {
      labels: [
        '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00'
      ],
      datasets: [
        {
          label: 'Printed',
          backgroundColor: [
            'Orange',
          ],
          fill: false,
          lineTension: 0.5,
          borderColor: 'rgba(0,0,0,1)',
          borderWidth: 2,
          data: [1, 1, 77, 42, 44, 48, 31, 29],
        },
      ],
    }

    this.server2Options = {
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Server 3 Printed by hour',
        },
      },
    }
  }

  render() {
    return (
      <Fragment>
        <div style={{ overflow: "auto", height: "760px" }}>
          {/**
           * Main data table
           */}
          <h1>
            OnSpot day 2 ({this.state.eventDate}) Statistics for{" "}
            {this.state.eventName}
          </h1>
          <table style={{ textAlign: "center" }} className='statistics_table'>
            <thead>
              <tr>
                <th>Registered</th>
                <th>Printed</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{this.state.totalData.registered}</td>
                <td>{this.state.totalData.printed}</td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <br />

          <div style={{ height: "500px" }}>
            <Line data={this.server1} options={this.server1Options} />
          </div>
          <div style={{ height: "500px" }}>
            <Line data={this.server2} options={this.server2Options} />
          </div>
        </div>
      </Fragment>
    );
  }

}

export default OnSpotOne