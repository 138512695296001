import React from 'react'
import '../Login/log_in.scss'
import UF_Button from "../../../nix_components/ui_components/controls/nx_button.js"
import {v4 as uuidv4} from "uuid"
import Nx_Ctrl_TextInput from "../../../nix_components/ui_components/controls/nx_ctrl_textinput"
import withRouter from "../../../nix_components/components/withRouter"

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            isError: false,
            errMsg: '',
            infoText : ''
        }
    }

    onChange = (val, field) => {
        this.state[field] = val
        this.forceUpdate()
    }

    handleValidation = (callback) => {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        let valid = false
        if (this.state.email !== null && this.state.email.length) {
            if (String(this.state.email).toLowerCase().match(emailRegex)) {
                global.UF.dataProvider.get(`users`, {email: `${this.state.email}`}, (users) => {
                    if (users.length) {
                        this.user = users[0]
                        valid = true
                        this.state.errMsg = ''
                    }
                    else {
                        valid = false
                        this.state.errMsg = `User with that email doesn't exists`
                    }
                    this.forceUpdate()
                    callback(valid)
                })
            } else {
                valid = false
                this.state.errMsg = 'Please insert right email format'
            }
        } else {
            valid = false
            this.state.errMsg = 'Email field cannot be empty'
        }
        this.forceUpdate()
        callback(valid)
    }

    onKeyDown = (e) => {
        if (e.keyCode == '13') {
            this.handleForgotPassword()
        }
    }

    handleForgotPassword = () => {
        this.handleValidation((valid) => {
            if(valid) {
                global.UF.dataProvider.get(`users`, {email : this.state.email}, (users) => {
                    if(!users.length) {
                        this.state.infoText = "There is no user with that email"
                        this.forceUpdate()
                    }
                    const currentUser = users[0]
                    let userUid = uuidv4()
                    const event_name = global.UF.handleEventBasedOnUrl();
                    const body = {
                        params : {
                            url : `${window.location.origin}/reset-password?id=${userUid}`,
                            registration : {
                                email : currentUser.email,
                            }
                        },
                        event_name : event_name
                    }
                    global.UF.makeRequest(`POST`, `/api/user/forgot-password`, body, true, () => {
                        currentUser.forgot_password_token = userUid;
                        currentUser.Save(() => {
                            this.setState({infoText : "Please check your email"})
                        })
                    }, (error) => {
                        console.log(error)
                    })
                }, true)
            }
        })
    }

    findImage = () => {
        const url = window.location.href;
        if(url.includes('realty')) {
            return "realtyon.png"
        }
        else if(url.includes('financemagnates')) {
            return "financemagnates.png"
        }
        else if(url.includes('ultimatefintech')) {
            return "ifxexpo.png"
        }
        else {
            return "ifxexpo.png"
        }
    }

    render() {
        return (
            <div className="uf_login">
                <div className="uf_login_card" style={{height: "320px"}}>
                    <div className="uf_login_logo_section" style={{height: "320px"}}>
                        <div className="uf_login_logo">
                            <img src={`./assets/images/${this.findImage()}`} alt={"logo"}/>
                        </div>
                    </div>
                    <div className="uf_login_login" style={{height: "320px"}}>
                        <div className="uf_login_text">
                            Forgot password
                        </div>
                        <div className="uf_login_textfield_wrapper">
                            <Nx_Ctrl_TextInput
                                onChange={(val) => this.onChange(val, `email`)}
                                label="Email"
                                error={this.state.errMsg.length > 0}
                                helperText={this.state.errMsg}
                                value={this.state.email}
                                onKeyDown={this.onKeyDown}
                                inputStyle={{height: "25px", borderRadius: "10px"}}
                            />
                        </div>
                        <div className={"uf_login_button_container"} style={{marginTop: "0"}}>
                            <UF_Button label="Send email" type={"button"} className="uf_login_login_button"
                                       onClick={() => this.handleForgotPassword()}/>
                        </div>
                        <br/>
                        <span style={{
                            display: this.state.infoText.length ? "block" : "none",
                            fontSize: "15px",
                            fontFamily: "Poppins",
                            fontWeight: "500",
                            textAlign : 'center',
                            color: "rgba(45, 93, 252, 0.90)",
                            marginTop : "-5px"
                        }}>{this.state.infoText}</span>
                    </div>
                </div>
            </div>
        )
    }

}


export default withRouter(ForgotPassword)
