import React, { Fragment, useEffect, useState } from "react";
import UF_Content from "../../../../nix_components/components/UF_Content";
import data_types from "../../../../nix_components/data/data_types";

export const EventsSettings = () => {
  const [scopeCondition, setScopeCondition] = useState(false);
  const permission = "event";

  const buttons = {};

  const formConfig = {
    rows: [
      [
        {
          field: "setting_key",
          label: "Setting",
          datatype: data_types.string,
          disabled: true,
        },
        {
          field: "setting_value",
          label: "Value",
        },
      ],
    ],
  };

  const findCurrentPermission = () => {
    const user =
      typeof localStorage.getItem("user") === "string"
        ? JSON.parse(localStorage.getItem("user"))
        : localStorage.getItem("user");
    const scopes = user.hasOwnProperty("scopes")
      ? typeof user.scopes === "string"
        ? JSON.parse(user.scopes)
        : user.scopes
      : null;
    const tempScope =
      scopes.findIndex(
        (p) => p.split(":")[0] === permission && p.includes("write")
      ) > -1;
    setScopeCondition(tempScope);
  };

  useEffect(() => {
    findCurrentPermission();
  }, []);

  const onSave = async (dataItem, callback) => {
    if (dataItem) {
      if (dataItem.isNew && dataItem.hasOwnProperty("event_id")) {
        dataItem.event_id = global.UF.event_id;
      }
      try {
        const setting_key = dataItem.setting_key;
        const event_settings = await global.UF.dataProvider.get_v2(
          `event_settings`,
          { event_id: global.UF.event_id, setting_key: setting_key }
        );
        if (dataItem.isNew && event_settings && event_settings.length) {
          global.UF.setAlertVisibility(
            true,
            `This event already has defined value for key ${setting_key}! Please edit!`,
            "warning"
          );
          if (callback) {
            callback();
          }
        } else {
          dataItem.Save(async () => {
            const temp_event_settings = await global.UF.makeRequest_v2(
              `POST`,
              `/api/init`,
              { event_id: global.UF.event_id, force: true },
              true
            );
            global.UF.EVENT_SETTINGS = temp_event_settings.body;
            if (callback) {
              callback();
            }
          });
        }
      } catch (err) {
        global.UF.setAlertVisibility(true, "No dataitem provided", "error");
        if (callback) {
          callback();
        }
      }
    } else {
      global.UF.setAlertVisibility(true, "No dataitem provided", "error");
      if (callback) {
        callback();
      }
    }
  };

  return (
    <Fragment>
      <UF_Content
        table="event_settings"
        title="Event Settings"
        permission="event"
        buttons={buttons}
        edit={scopeCondition}
        delete={false}
        export={scopeCondition}
        add={false}
        onSave={onSave}
        sync={scopeCondition}
        formConfig={formConfig}
      />
    </Fragment>
  );
};
