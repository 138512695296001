import React, { Fragment } from "react";
import UFButton from "../../nix_components/ui_components/controls/nx_button.js";
import Dialog from "@mui/material/Dialog/Dialog";
import Typography from "@mui/material/Typography/Typography";
import NxCtrlCheckbox from "../../nix_components/ui_components/controls/nx_ctrl_checkbox";
import UF_DATEPICKER from "../../nix_components/ui_components/controls/datetime.js";
import { CSVLink } from "react-csv";
import withRouter from "../../nix_components/components/withRouter";

class ExportDataModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      regularAttendees: false,
      sponsorAttendees: false,
      speakerAttendees: false,
      mediaAttendees: false,
      attendeesFrom: "",
      attendeesTo: "",
      data: [],
    };
    this.csvLink = React.createRef();
  }

  exportFields = [
    { field: "first_name", label: "Firstname" },
    { field: "last_name", label: "Lastname" },
    { field: "type", label: "Type" },
    { field: "email", label: "Email" },
    { field: "phone", label: "Phone" },
    { field: "position", label: "Position" },
    { field: "country", label: "Country" },
    { field: "nationality", label: "Nationality" },
    { field: "company", label: "Company" },
    { field: "category", label: "Category" },
    { field: "booth_numbers", label: "Booth Numbers" },
    { field: "sponsor_types", label: "Sponsor Types" },
    { field: "subcategory", label: "Subcategory" },
    { field: "status", label: "Attendee status" },
    { field: "registration_status", label: "Registration status" },
    { field: "paid", label: "Paid" },
    { field: "active", label: "Active" },
    { field: "package", label: "Package" },
    { field: "notes", label: "Notes" },
  ];

  onChange = (value, state) => {
    this.state[state] = value;
    this.forceUpdate();
  };

  onSelectAllChange = () => {
    this.setState({
      regularAttendees: true,
      sponsorAttendees: true,
      speakerAttendees: true,
      mediaAttendees: true,
    });
  };

  onExportClick = async () => {
    try {
      let types = [];

      if (this.state.regularAttendees) {
        types.push("regular");
      }
      if (this.state.sponsorAttendees) {
        types.push("sponsor");
      }
      if (this.state.speakerAttendees) {
        types.push("speaker");
      }
      if (this.state.mediaAttendees) {
        types.push("media-partner");
      }

      const filters = {
        event_id: global.UF.event_id,
        type: types,
      };

      let attendees = await global.UF.dataProvider.get_v2(
        `registration_attendee`,
        filters
      );

      if (!attendees || !attendees.length) {
        global.UF.setAlertVisibility(
          true,
          "There is no data to be exported!",
          "info"
        );
        return;
      }

      if (this.state.attendeesFrom !== "") {
        attendees = attendees.filter((attendee) => {
          return (
            attendee.created_at.getTime() >= this.state.attendeesFrom.getTime()
          );
        });
      }
      if (this.state.attendeesTo !== "") {
        attendees = attendees.filter(
          (attendee) =>
            attendee.created_at.getTime() <= this.state.attendeesTo.getTime()
        );
      }

      const data = this.generateItemsForExporting(attendees);
      this.setState({ data }, () => {
        if (this.state.data.length === 0) {
          global.UF.setAlertVisibility(
            true,
            "There is no data to be exported!",
            "info"
          );
          return;
        }
        this.forceUpdate(() => {
          this.csvLink.current.link.click();
        });
      });
    } catch (err) {
      global.UF.setAlertVisibility(true, err.toString(), "error");
      throw new Error(err.toString());
    }
  };

  formatValueForExporting = (value, type, reference) => {
    switch (type) {
      case "boolean":
        return value === 1 ? "Yes" : "No";
      case "string":
        return value ? value.replaceAll(",", ";") : "######";
      case "reference":
        return value ? this.formatListValue(value, reference) : "########";
    }
  };

  formatListValue(inputString, reference) {
    try {
      const idsArray = JSON.parse(inputString);
      if (
        Array.isArray(idsArray) &&
        idsArray.every((id) => typeof id === "string")
      ) {
        let value = "";
        idsArray.forEach((id) => {
          console.log(id, "id");
          value += `${global.UF.dataProvider.referenceProvider.getReferenceLabel(
            reference,
            id
          )};`;
        });
        return value;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  }

  generateItemsForExporting = (data) => {
    let tempArr = [];
    if (global.UF.EVENT_SETTINGS.EVENT === "FM") {
      this.exportFields.push(
        {
          field: "additional_data",
          subfield: "utm_source",
          label: "UTM Source",
        },
        {
          field: "additional_data",
          subfield: "utm_medium",
          label: "UTM Medium",
        },
        {
          field: "additional_data",
          subfield: "utm_campaign",
          label: "UTM Campaign",
        },
        {
          field: "additional_data",
          subfield: "utm_content",
          label: "UTM Content",
        },
        { field: "additional_data", subfield: "utm_term", label: "UTM Term" }
      );
    }
    data.forEach((item) => {
      let tempItem = {};
      this.exportFields.forEach((field) => {
        if (
          field.field === "country" ||
          field.field === "nationality" ||
          field.field === "additional_data"
        ) {
          if (field.field === "additional_data") {
            const additional_data = item["additional_data"]
              ? JSON.parse(item["additional_data"].replace(/\\/g, "\\\\"))
              : null;
            tempItem[field.label] =
              additional_data && typeof additional_data === "object"
                ? additional_data[field.subfield] !== null
                  ? additional_data[field.subfield]
                  : "#########"
                : "#########";
          } else {
            tempItem[field.label] = item[field.field]
              ? global.UF.dataProvider.referenceProvider.getReferenceLabel(
                  `countries`,
                  item[field.field]
                )
              : "#########";
          }
        } else {
          tempItem[field.label] = this.formatValueForExporting(
            item[field.field],
            item.table_structure.fieldsArr.find(
              (itemField) => itemField[`Field`] === field.field
            ).datatype,
            item.table_structure.fieldsArr.find(
              (itemField) => itemField[`Field`] === field.field
            ).reference
          );
        }
      });
      tempArr.push(tempItem);
    });
    return tempArr;
  };

  componentDidMount = () => {
    const pathname = this.props.router.location.pathname;
    const attendeeType = pathname.split("/")[3].split("-")[0];
    switch (attendeeType) {
      case "registrations":
        this.state.regularAttendees = true;
        break;
      case "sponsor":
        this.state.sponsorAttendees = true;
        break;
      case "speaker":
        this.state.speakerAttendees = true;
        break;
      case "media":
        this.state.mediaAttendees = true;
        break;
    }

    const attendeesFrom = new Date();
    const date = new Date();
    date.setDate(date.getDate() + 1);
    const attendeesTo = date;
    this.setState({ attendeesFrom, attendeesTo });
  };

  render() {
    return (
      <Fragment>
        <Dialog
          open={this.props.open}
          keepMounted
          onClose={this.props.onClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <div className="nx_modal_wrapper">
            <div className="nx_modal_title">
              <Typography variant="h5">Export data</Typography>
            </div>
            <div>
              <NxCtrlCheckbox
                label={"Select all"}
                onChange={this.onSelectAllChange}
                value={
                  this.state.regularAttendees &&
                  this.state.sponsorAttendees &&
                  this.state.speakerAttendees &&
                  this.state.mediaAttendees
                }
              />
            </div>
            <div>
              <NxCtrlCheckbox
                label={"Regular Attendees"}
                onChange={(value) => this.onChange(value, "regularAttendees")}
                value={this.state.regularAttendees}
              />
            </div>
            <div>
              <NxCtrlCheckbox
                label={"Sponsor Attendees"}
                onChange={(value) => this.onChange(value, "sponsorAttendees")}
                value={this.state.sponsorAttendees}
              />
            </div>
            <div>
              <NxCtrlCheckbox
                label={"Speaker Attendees"}
                onChange={(value) => this.onChange(value, "speakerAttendees")}
                value={this.state.speakerAttendees}
              />
            </div>
            <div>
              <NxCtrlCheckbox
                label={"Media Partner Attendees"}
                onChange={(value) => this.onChange(value, "mediaAttendees")}
                value={this.state.mediaAttendees}
              />
            </div>
            <div>
              <UF_DATEPICKER
                helperText={"From"}
                onChange={(value) => this.onChange(value, "attendeesFrom")}
                value={this.state.attendeesFrom}
              />
            </div>
            <div>
              <UF_DATEPICKER
                helperText={"To"}
                onChange={(value) => this.onChange(value, "attendeesTo")}
                value={this.state.attendeesTo}
              />
            </div>
            <div className="nx_buttons">
              <UFButton
                onClick={this.props.onClose}
                variant={"outlined"}
                label={"Cancel"}
              />
              <UFButton
                label={"Export"}
                onClick={async () => await this.onExportClick()}
              />
            </div>
            <div style={{ display: "none" }}>
              <CSVLink
                data={this.state.data}
                ref={this.csvLink}
                filename={`${global.UF.data.currentEvent.title}.csv`}
              />
            </div>
          </div>
        </Dialog>
      </Fragment>
    );
  }
}

export default withRouter(ExportDataModal);
