import React, {Fragment} from 'react';
import Dialog from "@mui/material/Dialog/Dialog";
import Typography from "@mui/material/Typography/Typography";
import withRouter from "../../nix_components/components/withRouter";
import UF_Button from "../../nix_components/ui_components/controls/nx_button.js";
import {
    Checkbox,
    FormControlLabel,
  } from "@mui/material";

class SetLanguagesModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            languages: [],
            eventLanguages: [],
            selectedLanguages: [],
        }
    }

    onChange = (value) => {
        console.log(this.state.selectedLanguages);
        if (this.state.selectedLanguages.includes(Number(value))) {
            const sl = this.state.selectedLanguages.filter((el) => {
                return el !== Number(value);
            });
            this.setState({selectedLanguages: sl});
        } else {
            if (!this.state.selectedLanguages.includes(Number(value))) {
                const sl = [...this.state.selectedLanguages, Number(value)];
                this.setState({selectedLanguages: sl});
            }
        }
    }

    loadLanguages =  async () => {
        await global.UF.dataProvider.get(`languages`, {}, (languages) => {
            this.setState({'languages': languages});    
        });
    }

    loadEventLanguages = async () => {
        await global.UF.dataProvider.get(`event_languages`, {event_id: this.props.eventId}, (eventLanguages) => {
            let setLanguages = [];
            eventLanguages.forEach(eventLanguage => {
                setLanguages.push(eventLanguage.language_id);
            });
            this.setState({eventLanguages: setLanguages});
        })
    }

    saveEventLanguages = async () => {
        const body = {
            eventId: this.props.eventId,
            languages: this.state.selectedLanguages
        };

        const result = await fetch(
            `/api/event/languages/save`,
            {
              method: "POST",
              headers: {
                "content-type": "application/json",
                accept: "application/json",
              },
              body: JSON.stringify(body)
            }
        );   
        
        this.props.onClose();
    }

    fillSelectedLanguages = () => {
        if (this.state.languages.length && this.state.eventLanguages.length) {
            let selectedLanguages = [];
            this.state.eventLanguages.forEach((eventLanguage) => {
                selectedLanguages.push(eventLanguage);
            });
            this.setState({selectedLanguages: selectedLanguages});
        }
    }


    componentDidMount = () => {
        this.loadLanguages();
        this.loadEventLanguages();
        setTimeout(() => {
            this.fillSelectedLanguages();
        }, 1000)
    }

    render() {
        return (
            <Fragment>
                <Dialog
                    open={this.props.open}
                    keepMounted
                    onClose={this.props.onClose}
                    aria-describedby='alert-dialog-slide-description'
                >
                    <div className='nx_modal_wrapper'>
                        <div className='nx_modal_title'>
                            <Typography variant='h5'>Set languages for {this.props.eventName}</Typography>
                        </div>
                        <div>
                            {this.state.languages.map((language, index) => {
                                return (
                                    <span key={index}>
                                        <FormControlLabel
                                            label={language.name}
                                            control={
                                                <Checkbox
                                                    value={language.id}
                                                    defaultChecked={this.state.eventLanguages.includes(language.id)}
                                                    onChange={(e) => this.onChange(e.target.value)}
                                                />
                                            }
                                        />
                                    </span>
                                )
                            })}
                        </div>
                        <div className="nx_buttons">
                            <UF_Button onClick={this.saveEventLanguages} variant={'contained'} label={'Save'} />
                            <UF_Button onClick={this.props.onClose} variant={'outlined'} label={'Cancel'} />
                        </div>
                    </div>
                </Dialog>
            </Fragment>
        )
    }

}

export default withRouter(SetLanguagesModal)