import React, { Fragment } from "react";
import UFContent from "../../../../nix_components/components/UF_Content";
import data_types from "../../../../nix_components/data/data_types";
import { validators } from "../../../../nix_components/data/data_validators";
import withRouter from "../../../../nix_components/components/withRouter";
import { IconButton, Tooltip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import PaymentIcon from "@mui/icons-material/Payment";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import ConfirmModalEmail from "../../../../components/modals/ConfirmModalEmail";
import DescriptionIcon from "@mui/icons-material/Description";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";

class RegularRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailItem: null,
      completedItem: null,
      permission: "registration",
      scopeCondition: false,
      loaded: false,
    };
  }

  buttons = {
    registrationStatus: (params) => {
      return params.row.status === "Under review" &&
        this.state.scopeCondition ? (
        <Fragment>
          <IconButton
            aria-label="delete"
            color="default"
            onClick={() => this.handleRegistrationStatus(params.row, true)}
          >
            <CheckCircleIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            color="default"
            onClick={() => this.handleRegistrationStatus(params.row, false)}
          >
            <UnpublishedIcon />
          </IconButton>
        </Fragment>
      ) : null;
    },
    registrationPaymentStatus: (params) => {
      return params.row.status === "Pending payment" &&
        params.row.ipn != null &&
        params.row.ipn.length > 1 &&
        this.state.scopeCondition ? (
        <Fragment>
          <Tooltip title={"Check payment status"}>
            <IconButton
              aria-label="delete"
              color="default"
              onClick={() => this.handlePaymentStatus(params.row)}
            >
              <PaymentIcon />
            </IconButton>
          </Tooltip>
        </Fragment>
      ) : null;
    },
  };

  popOverButtons = {
    pendingPayment: (rows) => {
      const valid =
        rows.filter((row) => row.status != "Pending payment").length == 0;
      return valid ? (
        <IconButton onClick={async () => await this.handlePopOverPayment(rows)}>
          <PaymentIcon fontSize={"large"} sx={{ color: "#2D5DFCB2" }} />
        </IconButton>
      ) : null;
    },
  };

  formConfig = {
    rows: [
      [
        {
          label:
            "Check if registration is already paid or is provided free of charge",
          datatype: data_types.boolean,
          field: "paid",
        },
      ],
      [
        {
          label: "Package",
          datatype: data_types.reference,
          reference: "packages",
          field: "package_id",
          onChange: () => {
            this.forceUpdate();
          },
        },
        {
          label: "Category",
          datatype: data_types.reference,
          reference: "package_categories",
          reference_groupby: "package_id",
          field: "category",
          onChange: () => {
            this.forceUpdate();
          },
        },
        {
          label: "Subcategory",
          field: "subcategory",
          datatype: data_types.reference,
          reference: "package_subcategories",
          reference_groupby: "category",
        },
      ],
      [{ field: "first_name", label: "First Name" }, "last_name", "email"],
      [
        {
          label: "Tickets",
          data: [1, 2, 3, 4, 5],
          datatype: data_types.select,
          field: "ticket_quantity",
        },
        "phone",
        "company",
      ],
      [
        {
          field: "status",
          label: "Status",
          datatype: data_types.reference,
          reference: "registrationStatuses",
        },
        {},
        {},
      ],
      [
        {
          label: "Notes",
          datatype: data_types.string,
          multiline: true,
          field: "notes",
          name: "notes",
          validators: [validators.isNull],
        },
      ],
    ],
  };

  gridProps = {
    fields: [
      "first_name",
      "website",
      "package_id",
      "status",
      "ticket_quantity",
      "paid",
      "company",
      "category",
      "subcategory",
      "invoice",
      "evidence",
      "notes",
      "created_at",
    ],
  };

  handlePopOverPayment = async (rows) => {
    const promises = rows.map(async (row) => {
      return this.handlePaymentLink(row);
    });
    try {
      await Promise.all(promises);
    } catch (err) {
      throw new Error(err.toString());
    }
    global.UF.setAlertVisibility(
      true,
      `Payment email resent to ${rows.length} registrations!`,
      "success"
    );
  };

  handleEmailValidation = async (dataItem) => {
    if (!dataItem) {
      throw new Error("Not valid registration provided!");
    }

    const email = dataItem.email.toLowerCase().trim();

    if (dataItem.isNew) {
      const registration = await global.UF.dataProvider.get_v2(
        `registrations`,
        {
          email: email,
          event_id: global.UF.event_id,
          type: "regular",
        }
      );

      if (registration.length) {
        throw new Error(
          `Registration email needs to be unique, a registration with email : ${email} already exists!`
        );
      }

      return true;
    }

    return true;
  };

  onSave = async (dataItem, callback) => {
    try {
      await this.handleEmailValidation(dataItem);
      if (global.UF.EVENT_SETTINGS.REGISTRATION_PAYMENT != "true") {
        dataItem.Save(() => {
          if (callback) {
            callback();
          }
        });
        return;
      }

      const changedFields = dataItem.GetChangedFields();

      if (!changedFields.hasOwnProperty("package_id")) {
        dataItem.Save(() => {
          if (callback) {
            callback();
          }
        });
        return;
      }

      try {
        const changedPackageID = changedFields[`package_id`];
        const dateOfRegistration = dataItem.isNew
          ? moment(new Date()).format("YYYY-MM-DD")
          : moment(dataItem.created_at).format("YYYY-MM-DD");
        const payable = await this.checkIfChangedPackageIsPayable(
          changedPackageID,
          dateOfRegistration
        );

        if (dataItem.isNew) {
          dataItem.id = uuidv4();
        }

        if (!payable) {
          if (!changedFields.hasOwnProperty("status")) {
            dataItem.status = "Under review";
          }
          dataItem.Save(async () => {
            if (callback) {
              if (dataItem.isNew) {
                await this.handleCreatingAttendeeAfterRegistration(dataItem.id);
              }
              callback();
            }
          });
          return;
        }

        const packages = await global.UF.dataProvider.get_v2(`packages`, {
          id: changedPackageID,
          event_id: global.UF.event_id,
        });

        if (!packages || !packages.length) {
          throw new Error(`There is no package with id ${changedPackageID}`);
        }

        const currentPackage = packages[0];
        const pricesOfCurrentPackage = JSON.parse(currentPackage.prices);
        const currentStatus = this.getStatusOfPayment(
          pricesOfCurrentPackage,
          dateOfRegistration
        );
        const variables = [
          {
            email: `${dataItem.email}`,
            substitutions: [
              {
                var: "link",
                value: `${global.UF.EVENT_SETTINGS.PAYMENT_STORE}&sku${currentStatus.external_id}=${dataItem.ticket_quantity}&merchant.transaction.id=${dataItem.id}&currency=${global.UF.EVENT_SETTINGS.DEFAULT_CURRENCY}`,
              },
              {
                var: "price",
                value: `${currentStatus.value}`,
              },
              {
                var: "name",
                value: `${dataItem.first_name} ${dataItem.last_name}`,
              },
            ],
          },
        ];
        const params = {
          recipient: {
            email: `${dataItem.email}`,
            first_name: `${dataItem.first_name}`,
            last_name: `${dataItem.last_name}`,
          },
          subject: global.UF.EVENT_SETTINGS.EMAILS_CATEGORY_CHANGE_SUBJECT,
          variables: variables,
          template_id:
            global.UF.EVENT_SETTINGS.EMAILS_CATEGORY_CHANGE_TEMPLATE_ID,
        };

        if (currentPackage.name?.toLowerCase().includes("extra")) {
          params.subject =
            global.UF.EVENT_SETTINGS.EMAILS_EXTRA_TICKETS_SUBJECT;
          params.template_id =
            global.UF.EVENT_SETTINGS.EMAILS_EXTRA_TICKETS_TEMPLATE_ID;
        }

        if (!changedFields.hasOwnProperty("status")) {
          dataItem.status = "Pending payment";
        }
        if (dataItem.isNew) {
          dataItem.Save(async () => {
            if (callback) {
              await this.handleCreatingAttendeeAfterRegistration(dataItem.id);
              callback();
            }
          });
        } else {
          dataItem.Save(async () => {
            const body = {
              params: params,
              event_id: global.UF.event_id,
            };

            try {
              await global.UF.makeRequest_v2(
                `POST`,
                `/api/registration/status-mail`,
                body,
                true
              );
              if (callback) callback();
            } catch (err) {
              throw new Error(err.toString());
            }
          });
        }
      } catch (err) {
        throw new Error(err.toString());
      }
    } catch (err) {
      global.UF.setAlertVisibility(true, err.toString(), "error");
      throw new Error(err);
    }
  };

  handleCreatingAttendeeAfterRegistration = async (registration_id) => {
    try {
      await global.UF.makeRequest_v2(
        `POST`,
        `/api/registration/missing-attendees`,
        { registration_id: registration_id }
      );
    } catch (err) {
      console.error(`Error  :${err}`);
      global.UF.setAlertVisibility(true, err.toString(), "error");
    }
  };

  onDataItemsLoad = (data, callback) => {
    if (data && data.length) {
      data.forEach((registration, index) => {
        global.UF.dataProvider.get(
          `attendees`,
          {
            registration_id: registration.id,
            status: "approved",
          },
          (attendees) => {
            registration.attendees_completed = attendees.length;
            if (index + 1 === data.length) {
              if (callback) {
                callback();
              }
            }
          }
        );
      });
    } else {
      callback();
    }
  };

  handleSetEmailItem = (item) => {
    this.setState({ emailItem: item });
  };

  handleRegistrationStatus = (item, bool) => {
    if (bool) {
      item.status = "Completed";
      global.UF.dataProvider.get(
        `attendees`,
        {
          registration_id: item.id,
          status: "pending",
          email: item.email,
        },
        (attendee) => {
          if (attendee && attendee.length) {
            const currentAttendee = attendee[0];
            currentAttendee.status = "approved";
            currentAttendee.qr_code = `qrcodes/${currentAttendee.unique_code}.png`;
            const personalization = [
              {
                email: `${currentAttendee.email}`,
                data: {
                  barcode: `${window.location.origin}/api/qrcodes/${currentAttendee.unique_code}.png`,
                },
              },
            ];
            const variables = [
              {
                email: `${currentAttendee.email}`,
                substitutions: [
                  {
                    var: "name",
                    value: `${currentAttendee.first_name} ${currentAttendee.last_name}`,
                  },
                  {
                    var: "company",
                    value: `${item.company}`,
                  },
                ],
              },
            ];
            const params = {
              recipient: {
                email: `${currentAttendee.email}`,
                first_name: `${currentAttendee.first_name}`,
                last_name: `${currentAttendee.last_name}`,
              },
              subject: global.UF.EVENT_SETTINGS.EMAILS_QR_CODE_SUBJECT,
              personalization: personalization,
              variables: variables,
              template_id: global.UF.EVENT_SETTINGS.EMAILS_QR_CODE_TEMPLATE_ID,
            };
            item.Save(() => {
              if (global.UF.EVENT_SETTINGS.EVENT === "FM") {
                const approvalInformationParams = {
                  recipient: {
                    email: `${global.UF.EVENT_SETTINGS.EMAILS_NOTIFY_REGISTRATION_APPROVAL_NOTIFY_EMAIL}`,
                    first_name: `${global.UF.EVENT_SETTINGS.EMAILS_NOTIFY_REGISTRATION_APPROVAL_FIRST_NAME}`,
                    last_name: `${global.UF.EVENT_SETTINGS.EMAILS_NOTIFY_REGISTRATION_APPROVAL_LAST_NAME}`,
                  },
                  variables: [
                    {
                      email: `${global.UF.EVENT_SETTINGS.EMAILS_NOTIFY_REGISTRATION_APPROVAL_NOTIFY_EMAIL}`,
                      substitutions: [
                        {
                          var: "name",
                          value: `${item.first_name} ${item.last_name}`,
                        },
                        {
                          var: "email",
                          value: `${item.email}`,
                        },
                        {
                          var: "company",
                          value: `${item.company}`,
                        },
                        {
                          var: "category",
                          value: `${
                            item.category
                              ? global.UF.dataProvider.referenceProvider.getReferenceLabel(
                                  `package_categories`,
                                  item.category
                                )
                              : "Missing category"
                          }`,
                        },
                      ],
                    },
                  ],
                  template_id:
                    global.UF.EVENT_SETTINGS
                      .EMAILS_NOTIFY_REGISTRATION_APPROVAL_TEMPLATE_ID,
                  subject:
                    global.UF.EVENT_SETTINGS
                      .EMAILS_NOTIFY_REGISTRATION_APPROVAL_SUBJECT,
                };
                const approvalBody = {
                  params: approvalInformationParams,
                  event_id: global.UF.event_id,
                };
                global.UF.makeRequest(
                  `POST`,
                  `/api/registration/status-mail`,
                  approvalBody,
                  true,
                  () => {
                    currentAttendee.Save(() => {
                      global.UF.makeRequest(
                        `POST`,
                        `/api/qr-code`,
                        { attendee_id: currentAttendee.unique_code },
                        false,
                        () => {
                          setTimeout(() => {
                            const body = {
                              params: params,
                              event_id: global.UF.event_id,
                            };
                            global.UF.makeRequest(
                              `POST`,
                              `/api/registration/status-mail`,
                              body,
                              true,
                              () => {},
                              (error) => {
                                if (error) {
                                  console.error(`Error: ${error}`);
                                }
                              }
                            );
                          }, 500);
                        },
                        (error) => {
                          if (error) {
                            console.error(`Error : ${error}`);
                          }
                        }
                      );
                    });
                  },
                  (error) => {
                    if (error) {
                      console.error(`Error : ${error}`);
                    }
                  }
                );
              } else {
                currentAttendee.Save(() => {
                  global.UF.makeRequest(
                    `POST`,
                    `/api/qr-code`,
                    { attendee_id: currentAttendee.unique_code },
                    false,
                    () => {
                      setTimeout(() => {
                        const body = {
                          params: params,
                          event_id: global.UF.event_id,
                        };
                        global.UF.makeRequest(
                          `POST`,
                          `/api/registration/status-mail`,
                          body,
                          true,
                          () => {},
                          (error) => {
                            if (error) {
                              console.error(`Error: ${error}`);
                            }
                          }
                        );
                      }, 500);
                    },
                    (error) => {
                      if (error) {
                        console.error(`Error : ${error}`);
                      }
                    }
                  );
                });
              }
            });
          } else {
            item.Save(() => {});
          }
        }
      );
    } else {
      item.status = "Rejected";
      global.UF.dataProvider.get(
        `attendees`,
        { registration_id: item.id, status: "pending" },
        (attendees) => {
          item.Save(() => {
            if (attendees && attendees.length) {
              attendees.forEach((attendee) => {
                attendee.status = "rejected";
                attendee.Save(() => {
                  const variables = [
                    {
                      email: `${attendee.email}`,
                      substitutions: [
                        {
                          var: "name",
                          value: `${attendee.first_name} ${attendee.last_name}`,
                        },
                      ],
                    },
                  ];
                  const params = {
                    recipient: {
                      email: `${attendee.email}`,
                      first_name: `${attendee.first_name}`,
                      last_name: `${attendee.last_name}`,
                    },
                    subject: global.UF.EVENT_SETTINGS.EMAILS_REJECTED_SUBJECT,
                    variables: variables,
                    template_id:
                      global.UF.EVENT_SETTINGS.EMAILS_REJECTED_TEMPLATE_ID,
                  };
                  const body = {
                    params: params,
                    event_id: global.UF.event_id,
                  };
                  global.UF.makeRequest(
                    `POST`,
                    `/api/registration/status-mail`,
                    body,
                    true,
                    () => {},
                    (error) => {
                      if (error) {
                        console.error(`Error: ${error}`);
                      }
                    }
                  );
                });
              });
            } else {
              item.Save(() => {});
            }
          });
        }
      );
    }
  };

  handleResendCompletedEmail_v2 = async (callback) => {
    const item = this.state.completedItem;
    try {
      const attendees = await global.UF.dataProvider.get_v2(`attendees`, {
        registration_id: item.id,
        status: "approved",
      });
      const promises = attendees.map(async (attendee) => {
        attendee.qr_code = `qrcodes/${attendee.unique_code}.png`;
        const personalization = [
          {
            email: `${attendee.email}`,
            data: {
              barcode: `${window.location.origin}/api/qrcodes/${attendee.unique_code}.png`,
            },
          },
        ];
        const variables = [
          {
            email: `${attendee.email}`,
            substitutions: [
              {
                var: "name",
                value: `${attendee.first_name} ${attendee.last_name}`,
              },
              {
                var: "company",
                value: `${item.company}`,
              },
            ],
          },
        ];
        const params = {
          recipient: {
            email: `${attendee.email}`,
            first_name: `${attendee.first_name}`,
            last_name: `${attendee.last_name}`,
          },
          subject: global.UF.EVENT_SETTINGS.EMAILS_QR_CODE_SUBJECT,
          personalization: personalization,
          variables: variables,
          template_id: global.UF.EVENT_SETTINGS.EMAILS_QR_CODE_TEMPLATE_ID,
        };
        if (Object.keys(attendee.GetChangedFields()).length) {
          attendee.Save(async () => {
            await global.UF.makeRequest_v2(`POST`, `/api/qr-code`, {
              attendee_id: attendee.unique_code,
            });
            setTimeout(async () => {
              const body = {
                params: params,
                event_id: global.UF.event_id,
              };
              await global.UF.makeRequest_v2(
                `POST`,
                `/api/registration/status-mail`,
                body,
                true
              );
            }, 500);
          });
        } else {
          await global.UF.makeRequest_v2(`POST`, `/api/qr-code`, {
            attendee_id: attendee.unique_code,
          });
          setTimeout(async () => {
            const body = {
              params: params,
              event_id: global.UF.event_id,
            };
            await global.UF.makeRequest_v2(
              `POST`,
              `/api/registration/status-mail`,
              body,
              true
            );
          }, 500);
        }
      });
      await Promise.all(promises);
      if (callback) {
        callback();
      }
    } catch (error) {
      callback(error);
    }
  };

  getStatusOfPayment = (statuses, dateOfRegistration) => {
    let currentStatus = statuses["on_spot"];
    Object.keys(statuses).forEach((key) => {
      if (
        dateOfRegistration <=
        moment(new Date(statuses[key][`expires_at`])).format("YYYY-MM-DD")
      ) {
        currentStatus = statuses[key];
      }
    });
    return currentStatus;
  };

  checkIfChangedPackageIsPayable = async (package_id, date) => {
    try {
      const packages = await global.UF.dataProvider.get_v2(`packages`, {
        id: package_id,
      });

      if (!packages || !packages.length) {
        throw new Error(`There is no package with id (1) : ${package_id}`);
      }

      const currentPackage = packages[0];
      const currentStatus = this.getStatusOfPayment(
        JSON.parse(currentPackage.prices),
        date
      );

      return currentStatus.value > 0;
    } catch (err) {
      throw new Error(err.toString());
    }
  };

  handlePaymentLink = async (item) => {
    const package_id = item.package_id;
    const date_of_registration = moment(
      item.created_at ? item.created_at : new Date()
    ).format("YYYY-MM-DD");

    try {
      const packages = await global.UF.dataProvider.get_v2("packages", {
        id: package_id,
        event_id: global.UF.event_id,
      });
      if (!packages || !packages.length) {
        throw new Error(`There is no package with id (2) : ${package_id}`);
      }

      const current_package = packages[0];
      const prices =
        typeof current_package.prices == "string"
          ? JSON.parse(current_package.prices)
          : current_package.prices;

      const status = this.getStatusOfPayment(prices, date_of_registration);
      const variables = [
        {
          email: `${item.email}`,
          substitutions: [
            {
              var: "link",
              value: `${global.UF.EVENT_SETTINGS.PAYMENT_STORE}&sku${status.external_id}=${item.ticket_quantity}&merchant.transaction.id=${item.id}&currency=${global.UF.EVENT_SETTINGS.DEFAULT_CURRENCY}`,
            },
            {
              var: "name",
              value: `${item.first_name} ${item.last_name}`,
            },
          ],
        },
      ];
      const params = {
        recipient: {
          email: `${item.email}`,
          first_name: `${item.first_name}`,
          last_name: `${item.last_name}`,
        },
        subject: global.UF.EVENT_SETTINGS.EMAILS_PENDING_PAYMENT_SUBJECT,
        variables: variables,
        template_id:
          global.UF.EVENT_SETTINGS.EMAILS_PENDING_PAYMENT_TEMPLATE_ID,
      };

      if (current_package.name?.toLowerCase().includes("extra")) {
        params.subject = global.UF.EVENT_SETTINGS.EMAILS_EXTRA_TICKETS_SUBJECT;
        params.template_id =
          global.UF.EVENT_SETTINGS.EMAILS_EXTRA_TICKETS_TEMPLATE_ID;
      }

      const body = {
        params: params,
        event_id: global.UF.event_id,
      };
      await global.UF.makeRequest_v2(
        `POST`,
        `/api/registration/status-mail`,
        body,
        true
      );
    } catch (err) {
      throw new Error(err.toString());
    }
  };

  handleResendPaymentLink = async (callback) => {
    try {
      await this.handlePaymentLink(this.state.emailItem);
      if (callback) {
        callback();
      }
    } catch (err) {
      throw new Error(err.toString());
    }
  };

  handlePaymentStatus = (dataItem) => {
    const ipn =
      typeof dataItem.ipn === "string"
        ? JSON.parse(dataItem.ipn)
        : dataItem.ipn;
    global.UF.makeRequest(
      `GET`,
      `/api/transaction/${global.UF.event_id}/${ipn.invoiceId}`,
      {},
      false,
      (transaction) => {
        if (transaction.body.hasOwnProperty("processingInfo")) {
          if (transaction.body.processingInfo.processingStatus === "SUCCESS") {
            let url = `${window.location.origin}/api/payment-redirect?no-redirect=true&`;
            Object.keys(ipn).forEach((key, index) => {
              if (key === "invoiceStatus") {
                url += `invoiceStatus=APPROVED${
                  index + 1 < Object.keys(ipn).length ? "&" : ""
                }`;
              } else {
                url += `${key}=${ipn[key]}${
                  index + 1 < Object.keys(ipn).length ? "&" : ""
                }`;
              }
            });
            global.UF.makeRequest(
              `GET`,
              url,
              {},
              true,
              () => {
                alert(`Invoice sent to ${dataItem.email}`);
              },
              (error) => {
                console.error(
                  `Error occurred while trying to handle payment status : ${error}`
                );
              }
            );
          } else {
            console.log(transaction);
            alert("This transaction is not successful");
          }
        } else {
          console.log(transaction);
          alert("This transaction is not successful");
        }
      },
      (error) => {
        console.error(
          `Error occurred while trying to handle payment status : ${error}`
        );
      }
    );
  };

  handleCustomGridManipulation = () => {
    const ds = global.UF.data_structure["registrations"];
    const fields = ds.fieldsArr;
    fields.find((field) => field.Field === "status").onClick = (dataItem) => {
      if (dataItem.status === "Pending payment") {
        this.setState({ emailItem: dataItem });
      }
      if (dataItem.status === "Completed") {
        this.setState({ completedItem: dataItem });
      }
    };
    fields.find((field) => field.Field === "ticket_quantity").renderCell = (
      dataItem
    ) => {
      return (
        <Tooltip
          title={`${dataItem.value ? dataItem.value : 0} (${
            dataItem.row.attendees_completed
          })`}
        >
          <a
            href={this.navigateToAttendees(dataItem.row)}
            target="_blank"
            style={{ cursor: "pointer", textDecoration: "none" }}
          >
            {dataItem.value ? dataItem.value : 0} (
            {dataItem.row.attendees_completed})
          </a>
        </Tooltip>
      );
    };
    fields.find((field) => field.Field == "invoice").renderCell = (params) => {
      const dataItem = params.row;
      return (
        <Tooltip title={dataItem[`invoice`]}>
          <a
            href={dataItem[`invoice`]}
            target="_blank"
            style={{
              cursor: "pointer",
              textDecoration: "none",
              color: "green",
            }}
          >
            {<DescriptionIcon />}
          </a>
        </Tooltip>
      );
    };
  };

  navigateToAttendees = (dataItem) => {
    const registration = dataItem;
    switch (registration.type) {
      case "regular":
        return `${window.location.origin}/events/${global.UF.event_id}/registrations-attendees?registration_id=${registration.id}`;
      case "sponsor":
        return `${window.location.origin}/events/${global.UF.event_id}/sponsor-attendees?registration_id=${registration.id}`;
      case "speaker":
      case "speaker-guest":
        return `${window.location.origin}/events/${global.UF.event_id}/speaker-attendees?registration_id=${registration.id}`;
    }
  };

  componentDidMount() {
    global.UF.dataProvider.datastructure["registrations"].filters = {
      type: "regular",
    };
    this.handleCustomGridManipulation();
    this.findCurrentPermission();
  }

  renderResendCompletedLinkModal = () => {
    return (
      <ConfirmModalEmail
        open={this.state.completedItem !== null}
        onClose={() => this.setState({ completedItem: null })}
        email={this.state.completedItem ? this.state.completedItem.email : ""}
        onSendEmail={this.handleResendCompletedEmail_v2}
        text={"Are you sure you want to resend completed email to"}
      />
    );
  };

  renderResendPaymentLinkModal = () => {
    return (
      <ConfirmModalEmail
        open={this.state.emailItem !== null}
        onClose={() => this.setState({ emailItem: null })}
        email={this.state.emailItem ? this.state.emailItem.email : ""}
        onSendEmail={this.handleResendPaymentLink}
        text={"Are you sure you want to resend payment link to"}
      />
    );
  };

  handleBeforeAddNew = (dataItem, callback) => {
    dataItem.type = "regular";
    dataItem.event_id = global.UF.event_id;
    if (callback) {
      callback();
    }
  };

  findCurrentPermission = () => {
    const user =
      typeof localStorage.getItem("user") === "string"
        ? JSON.parse(localStorage.getItem("user"))
        : localStorage.getItem("user");
    const scopes = user.hasOwnProperty("scopes")
      ? typeof user.scopes === "string"
        ? JSON.parse(user.scopes)
        : user.scopes
      : null;
    this.state.scopeCondition =
      scopes.findIndex(
        (permission) =>
          permission.split(":")[0] === this.state.permission &&
          permission.includes("write")
      ) > -1;
    this.setState({ loaded: true });
  };

  render() {
    return (
      <Fragment>
        {this.state.completedItem && this.renderResendCompletedLinkModal()}
        {this.state.emailItem && this.renderResendPaymentLinkModal()}
        <UFContent
          table="registrations"
          id={this.props.event_id}
          registrationType={"regular"}
          title="regular registration"
          helper="Important notice! For every registration with status 'Completed', an approved attendee with same information will be created!"
          permission={"registration"}
          formConfig={this.formConfig}
          gridProps={this.gridProps}
          buttons={this.buttons}
          export={this.state.scopeCondition}
          edit={this.state.scopeCondition}
          delete={this.state.scopeCondition}
          add={this.state.scopeCondition}
          sync={this.state.scopeCondition}
          popOverButtons={this.popOverButtons}
          onSave={this.onSave}
          onDataItemsLoad={this.onDataItemsLoad}
          beforeAddNew={this.handleBeforeAddNew}
        />
      </Fragment>
    );
  }
}

export default withRouter(RegularRegistration);
