import React, { useEffect, useState } from "react";
import NXDialog from "../../nix_components/ui_components/dialogs/nx_dialog";
import Grid from "../../nix_components/ui_components/layouts/Grid/Grid";
import NxCtrlSelect from "../../nix_components/ui_components/controls/nx_ctrl_select";
import datatypes from "../../nix_components/data/data_types";
import moment from "moment";

const TransferDataModal = (props) => {
  const [events, setEvents] = useState([]);
  const [tempEvents, setTempEvents] = useState([]);
  const [targetedEvent, setTargetedEvent] = useState(null);

  const handleTransferData = async () => {
    if (targetedEvent) {
      const table = props.table;
      try {
        const data = await global.UF.dataProvider.get_v2(
          table,
          { event_id: global.UF.event_id },
          false
        );
        const timestampFields = global.UF.data_structure[
          table
        ].fieldsArr.filter((field) => field.datatype === datatypes.time);
        data.forEach((item) => {
          item.event_id = targetedEvent.id;
          timestampFields.forEach((field) => {
            item[field.Field] = item[field.Field]
              ? moment(item[field.Field]).utc().format("YYYY-MM-DD HH:mm:ss")
              : null;
          });
        });
        const request = {
          table: table,
          data: data,
        };
        await global.UF.makeRequest_v2(`POST`, `/api/transfer`, request, true);
        props.onClose();
      } catch (err) {
        global.UF.setAlertVisibility(
          true,
          `Error occurred while trying to transfer data to another event. Error : \n ${err}`,
          "error"
        );
      }
    } else {
      global.UF.setAlertVisibility(
        true,
        "Please choose targeted event in order to proceed with transfering data",
        "error"
      );
    }
  };

  const fetchEvents = async () => {
    const events = await global.UF.dataProvider.get_v2(`events`, {});
    if (events && events.length) {
      let tempEvents = events.filter(
        (event) => event.id !== global.UF.event_id
      );
      tempEvents = tempEvents.map((event) => event.name);
      setEvents(events);
      setTempEvents(tempEvents);
    } else {
      global.UF.setAlertVisibility(true, "No events fetched", "warning");
    }
  };

  const handleEventChange = (value) => {
    const currentEvent = events.find((event) => event.name === value);
    setTargetedEvent(currentEvent);
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const buttons = [
    {
      label: "Yes",
      onClick: handleTransferData,
      variant: "contained",
    },
    {
      label: "Cancel",
      onClick: props.onClose,
      variant: "outlined",
    },
  ];

  return (
    <NXDialog
      buttons={buttons}
      open={props.open}
      sx={{ overflowY: "hidden" }}
      title={`Your current event is ${global.UF.data.currentEvent.title}`}
    >
      <Grid style={{ paddingTop: "30px" }}>
        <NxCtrlSelect
          data={tempEvents}
          onChange={handleEventChange}
          fieldConfig={{ label: "Choose targeted event", multiple: false }}
          value={targetedEvent ? targetedEvent.name : ""}
        />
      </Grid>
    </NXDialog>
  );
};
export default TransferDataModal;
