import React, { Fragment } from "react";
import UF_Content from "../../../../nix_components/components/UF_Content";
import data_types from "../../../../nix_components/data/data_types";
import { IconButton } from "@mui/material";
import PasswordIcon from "@mui/icons-material/Password";
import SetPasswordModal from "../../../../components/modals/SetPasswordModal";
import UF_Permissions from "../../../../controls/UF_Permissions";

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: "user",
      scopeCondition: false,
      passwordItem: null,
      loaded: false,
    };
  }

  buttons = {
    setPassword: (params) => {
      return this.state.scopeCondition ? (
        <IconButton
          aria-label="edit"
          color="default"
          onClick={() => this.setState({ passwordItem: params.row })}
        >
          <PasswordIcon />
        </IconButton>
      ) : null;
    },
  };

  formConfig = {
    rows: [
      [
        "email",
        {
          label: "System administrator",
          field: "is_admin",
          datatype: data_types.boolean,
        },
      ],
      [
        {
          label: "Permissions",
          customControl: (props, onValueChange, validationMsg) => {
            return (
              <UF_Permissions
                dataItem={props.dataItem}
                {...props.fieldConfig}
                onChange={onValueChange}
                read_only={props.read_only}
                error={validationMsg != false}
                helperText={
                  typeof validationMsg == data_types.string ? validationMsg : ""
                }
                value={props.dataItem[props.fieldConfig.field]}
              />
            );
          },
          field: "scopes",
        },
      ],
    ],
  };

  setPasswordItemNull = () => {
    this.state.passwordItem = null;
    this.forceUpdate();
  };

  setPassword = (password, user) => {
    global.UF.makeRequest(
      `POST`,
      `/api/set-password`,
      { id: user.id, password: password },
      true,
      (success) => {
        this.setState({ passwordItem: null });
      },
      (error) => {
        if (error) {
          console.error(error);
        }
      }
    );
  };

  renderSetPasswordModal = () => {
    return (
      <SetPasswordModal
        open={this.state.passwordItem !== null}
        onClose={() => this.setState({ passwordItem: null })}
        onSetPassword={(password) =>
          this.setPassword(password, this.state.passwordItem)
        }
      />
    );
  };

  findCurrentPermission = () => {
    const user =
      typeof localStorage.getItem("user") === "string"
        ? JSON.parse(localStorage.getItem("user"))
        : localStorage.getItem("user");
    const scopes = user.hasOwnProperty("scopes")
      ? typeof user.scopes === "string"
        ? JSON.parse(user.scopes)
        : user.scopes
      : null;
    this.state.scopeCondition =
      scopes.findIndex(
        (permission) =>
          permission.split(":")[0] === this.state.permission &&
          permission.includes("write")
      ) > -1;
    this.setState({ loaded: true });
  };

  componentDidMount() {
    this.findCurrentPermission();
    global.UF.dataProvider.datastructure[`users`].fieldsArr.find(
      (field) => field.Field == "is_admin"
    ).onChange = () => {
      this.forceUpdate();
    };
  }

  render() {
    return (
      <Fragment>
        {this.state.passwordItem && this.renderSetPasswordModal()}
        <UF_Content
          table="users"
          title="Users"
          permission="user"
          buttons={this.buttons}
          edit={this.state.scopeCondition}
          export={this.state.scopeCondition}
          delete={this.state.scopeCondition}
          formConfig={this.formConfig}
          add={this.state.scopeCondition}
          sync={this.state.scopeCondition}
        />
      </Fragment>
    );
  }
}

export default Users;
