import React, { Fragment } from 'react';
import { Typography, Dialog, TextField, InputLabel, Input, FormControl } from '@mui/material';
import withRouter from '../../nix_components/components/withRouter';
import UF_Button from '../../nix_components/ui_components/controls/nx_button';

class AgendaTranslationsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            eventLanguages: [],
            languages: [],
            selectedLanguages: [],
            translations: {}
        }
    }

    loadEventLanguages = async () => {
        await global.UF.dataProvider.get(`event_languages`, { event_id: this.props.eventId }, (eventLanguages) => {
            const langs = [];
            eventLanguages.forEach(lang => {
                langs.push(lang.language_id);
            })
            this.setState({eventLanguages: langs});
        });
    }

    loadLanguages =  async () => {
        await global.UF.dataProvider.get(`languages`, {}, (languages) => {
            this.setState({'languages': languages});    
        });
    }

    loadTranslations = async () => {
        await global.UF.dataProvider.get(`translations`, {model_id:this.props.modelId}, (translations) => {
            let translationsObject = {};
            if (translations.length) {
                translations.forEach((translation) => {
                    let x = {};
                    x[translation.field_name] = translation.field_value;
                    let languageId = translation.language_id;

                    if (!translationsObject[languageId]) {
                        translationsObject[languageId] = {}; // initialize an empty object for each language
                    }

                    translationsObject[languageId] = { ...translationsObject[languageId], ...x }; // merge new translation with existing ones
                });

                this.setState({ 'translations': translationsObject });
            }
        });
    }

    setSelectedLanguages = () => {
        let setSelectedLanguages = [];
        if (this.state.languages.length && this.state.eventLanguages.length) {
            this.state.languages.forEach((lang) => {
                if (this.state.eventLanguages.includes(lang.id)) {
                    setSelectedLanguages.push({id: lang.id, name: lang.name, shortName: lang.short_name})
                }
            });
        }
        this.setState({selectedLanguages: setSelectedLanguages});
    }

    saveDescription = (languageId, value) => {
        this.setState(prevState => {
            let updatedTranslation = prevState.translations;
            if (updatedTranslation.hasOwnProperty(languageId)) {
                updatedTranslation[languageId].description = value;
            } else {
                updatedTranslation[languageId] = {description: value};
            }
            return { translations: updatedTranslation };
          });
    }

    saveTitle = (languageId, value) => {
        this.setState(prevState => {
            let updatedTranslation = prevState.translations;
            if (updatedTranslation.hasOwnProperty(languageId)) {
                updatedTranslation[languageId].title = value;
            } else {
                updatedTranslation[languageId] = {title: value};
            }
            return { translations: updatedTranslation };
          });
    }

    saveTranslations = async () => {
        let translations = this.state.translations;
        let translationsForUpdate = [];

            for (let key in translations) {
            const element = translations[key];
            const obj = {
                title: element.title,
                description: element.description,
                languageId: key
            };
            translationsForUpdate.push(obj);
        }

        const body = {
            modelId: this.props.modelId,
            translations: translationsForUpdate
        };

        const result = await fetch(
            `/api/translations/save`,
            {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json'
                },
                body: JSON.stringify(body)
            }
        )

        this.props.onClose();
    }

    componentDidMount = () => {
        this.loadEventLanguages();
        this.loadLanguages();
        this.loadTranslations();
        setTimeout(() => {
            this.setSelectedLanguages();
        }, 300)
    }

    render() {
        return (
            <Fragment>
                <Dialog
                    open={this.props.open}
                    keepMounted
                    onClose={this.props.onClose}
                    aria-describedby='alert-dialog-slide-description'
                >
                    <div className='nx_modal_wrapper'>
                        <div className='nx_modal_title'>
                            <Typography variant='h5'>Set translations for {this.props.modelName}</Typography>
                        </div>
                        <div>
                            {this.state.selectedLanguages.map((language, index) => {
                                return (         
                                    <div key={index}>
                                        <Typography variant='h7'><b>{language.name}</b></Typography>
                                        <br />
                                        Title &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Input
                                            onChange={(e) => this.saveTitle(language.id, e.target.value)}
                                            defaultValue={this.state.translations.hasOwnProperty(Number(language.id)) ? this.state.translations[language.id].title : ""}
                                            variant="outlined"
                                        />
                                        <br />
                                        <br />
                                        <InputLabel>
                                            Description
                                        </InputLabel>
                                        <FormControl sx={{ width: "100%" }}>
                                            <TextField
                                                onChange={(e) => this.saveDescription(language.id, e.target.value)}
                                                defaultValue={this.state.translations.hasOwnProperty(Number(language.id)) ? this.state.translations[language.id].description : ""}
                                                multiline={true}
                                            />
                                        </FormControl>
                                    </div>
                                );
                            })}
                        </div>
                        <div className='nx_buttons'>
                            <UF_Button onClick={this.saveTranslations} variant={'contained'} label={'Save'} />
                            <UF_Button onClick={this.props.onClose} variant={'outlined'} label={'Cancel'} />
                        </div>
                    </div>
                </Dialog>
            </Fragment>
        );
    }
}

export default withRouter(AgendaTranslationsModal);