/* eslint-disable react/jsx-pascal-case */
import React, { Fragment } from "react";
import UF_Content from "../../../../nix_components/components/UF_Content";
import withRouter from "../../../../nix_components/components/withRouter";
import data_types from "../../../../nix_components/data/data_types";
import { validators } from "../../../../nix_components/data/data_validators";
import { IconButton } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import ExportDataModal from "../../../../components/modals/ExportDataModal";
import ConfirmModalEmail from "../../../../components/modals/ConfirmModalEmail";
import VerifiedIcon from "@mui/icons-material/Verified";

class Attendees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      title: null,
      permission: null,
      loaded: false,
      emailItem: null,
      export: false,
      scopeCondition: false,
    };
  }

  buttons = {
    attendeeStatus: (params, fetchData) => {
      return params.row.status === "pending" &&
        params.row.registration_status !== "Pending payment" &&
        this.state.scopeCondition ? (
        <Fragment>
          <IconButton
            aria-label="delete"
            color="default"
            onClick={() =>
              this.handleAttendeeStatus(params.row, true, fetchData)
            }
          >
            <CheckCircleIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            color="default"
            onClick={() =>
              this.handleAttendeeStatus(params.row, false, fetchData)
            }
          >
            <UnpublishedIcon />
          </IconButton>
        </Fragment>
      ) : null;
    },
  };

  tools = {
    export: () => {
      return this.state.scopeCondition ? (
        <Tooltip title={"Export"} placement="bottom-start">
          <IconButton
            aria-label="export"
            color="default"
            onClick={() => this.setState({ export: true })}
          >
            <DriveFolderUploadIcon />
          </IconButton>
        </Tooltip>
      ) : null;
    },
  };

  popOverButtons = {
    sendApproval: (rows) => {
      const valid = rows.filter((row) => row.status != "approved").length == 0;
      return valid ? (
        <IconButton
          onClick={async () => await this.handleSendingApprovalEmailBulk(rows)}
        >
          <VerifiedIcon fontSize={"large"} sx={{ color: "#2D5DFCB2" }} />
        </IconButton>
      ) : null;
    },
  };

  formConfig = {
    rows: [
      ["first_name", "last_name", "email"],
      [
        {
          field: "status",
          datatype: data_types.reference,
          reference: "attendeesStatuses",
          label: "Status",
        },
        "position",
        "phone",
      ],
      [
        {
          field: "country",
          label: "Country",
          datatype: data_types.reference,
          reference: "countries",
        },
        {
          field: "nationality",
          label: "Nationality",
          datatype: data_types.reference,
          reference: "countries",
        },
      ],
      [
        {
          label: "Notes",
          datatype: data_types.string,
          multiline: true,
          field: "notes",
          name: "notes",
          validators: [validators.isNull],
        },
      ],
    ],
  };

  gridProps = {
    fields: [
      "first_name",
      "company",
      "category",
      "subcategory",
      "phone",
      "position",
      "country",
      "status",
      "sponsor_types",
      "active",
      "notes",
      "updated_at",
    ],
  };

  handleAttendeeStatus = (item, bool, fetchData) => {
    global.UF.dataProvider.get(
      `attendees`,
      { id: item.id, status: "pending" },
      (attendee) => {
        if (attendee && attendee.length) {
          const currentAttendee = attendee[0];
          if (bool) {
            currentAttendee.status = "approved";
            currentAttendee.qr_code = `qrcodes/${currentAttendee.unique_code}.png`;
            const personalization = [
              {
                email: `${currentAttendee.email}`,
                data: {
                  barcode: `${window.location.origin}/api/qrcodes/${currentAttendee.unique_code}.png`,
                },
              },
            ];
            const variables = [
              {
                email: `${currentAttendee.email}`,
                substitutions: [
                  {
                    var: "name",
                    value: `${currentAttendee.first_name} ${currentAttendee.last_name}`,
                  },
                  {
                    var: "company",
                    value: `${item.company}`,
                  },
                ],
              },
            ];
            const params = {
              recipient: {
                email: `${currentAttendee.email}`,
                first_name: `${currentAttendee.first_name}`,
                last_name: `${currentAttendee.last_name}`,
              },
              subject: global.UF.EVENT_SETTINGS.EMAILS_QR_CODE_SUBJECT,
              personalization: personalization,
              variables: variables,
              template_id: global.UF.EVENT_SETTINGS.EMAILS_QR_CODE_TEMPLATE_ID,
            };
            currentAttendee.Save(() => {
              global.UF.makeRequest(
                `POST`,
                `/api/qr-code`,
                { attendee_id: currentAttendee.unique_code },
                true,
                () => {
                  setTimeout(() => {
                    const body = {
                      params: params,
                      event_id: global.UF.event_id,
                    };
                    global.UF.makeRequest(
                      `POST`,
                      `/api/registration/status-mail`,
                      body,
                      true,
                      () => {
                        if (fetchData) {
                          fetchData();
                        }
                      },
                      (error) => {
                        if (error) {
                          console.error(`Error: ${error}`);
                        }
                      }
                    );
                  }, 500);
                },
                (error) => {
                  if (error) {
                    console.error(`Error : ${error}`);
                  }
                }
              );
            });
          } else {
            currentAttendee.status = "rejected";
            const variables = [
              {
                email: `${currentAttendee.email}`,
                substitutions: [
                  {
                    var: "name",
                    value: `${currentAttendee.first_name} ${currentAttendee.last_name}`,
                  },
                ],
              },
            ];
            const params = {
              recipient: {
                email: `${currentAttendee.email}`,
                first_name: `${currentAttendee.first_name}`,
                last_name: `${currentAttendee.last_name}`,
              },
              subject: global.UF.EVENT_SETTINGS.EMAILS_REJECTED_SUBJECT,
              variables: variables,
              template_id: global.UF.EVENT_SETTINGS.EMAILS_REJECTED_TEMPLATE_ID,
            };
            currentAttendee.Save(() => {
              const body = {
                event_id: global.UF.event_id,
                params: params,
              };
              global.UF.makeRequest(
                `POST`,
                `/api/registration/status-mail`,
                body,
                true,
                () => {
                  if (fetchData) {
                    fetchData();
                  }
                },
                (error) => {
                  if (error) {
                    console.error(`Error: ${error}`);
                  }
                }
              );
            });
          }
        }
      }
    );
  };

  getTypeOfAttendees = (pathname) => {
    const attendeeType = pathname.split("/")[3].split("-")[0];
    let title = "";
    let tempPermission = "";
    // eslint-disable-next-line default-case
    switch (attendeeType) {
      case "registrations":
        tempPermission = "registration";
        title = "Regular Attendees";
        break;
      case "sponsor":
        tempPermission = "sponsor";
        this.gridProps.fields.push("sponsor_types");
        title = "Sponsor Attendees";
        break;
      case "speaker":
        tempPermission = "speaker";
        title = "Speaker Attendees";
        break;
      case "media":
        tempPermission = "media-partner";
        title = "Media Partner Attendees";
    }
    this.state.type =
      attendeeType === "speaker"
        ? ["speaker", "speaker-guest"]
        : attendeeType === "media"
        ? "media-partner"
        : attendeeType;
    this.state.permission = tempPermission;
    this.state.title = title;
  };

  shouldComponentUpdate = (nextProps) => {
    if (
      this.props.router.location.pathname !== nextProps.router.location.pathname
    ) {
      this.getTypeOfAttendees(nextProps.router.location.pathname);
      global.UF.dataProvider.datastructure["registration_attendee"].filters = {
        event_id: this.props.event_id,
        type: this.state.type === "registrations" ? "regular" : this.state.type,
      };
    }
    return true;
  };

  handleCustomGridManipulation = () => {
    const ds = global.UF.data_structure["registration_attendee"];
    const fields = ds.fieldsArr;
    fields.find((field) => field.Field === "status").onClick = (dataItem) => {
      if (dataItem.status === "approved") {
        this.setState({ emailItem: dataItem });
      }
    };
  };

  onSave = (dataItem, callback) => {
    global.UF.dataProvider.save(`attendees`, dataItem, () => {
      if (callback) {
        callback();
      }
    });
  };

  onDelete = (dataItem, callback) => {
    global.UF.dataProvider.get(`attendees`, { id: dataItem.id }, (attendee) => {
      attendee[0].Delete(() => {
        if (callback) {
          callback();
        }
      });
    });
  };

  handleSendApprovedEmail = async (item, callback) => {
    try {
      const personalization = [
        {
          email: `${item.email}`,
          data: {
            barcode: `${window.location.origin}/api/qrcodes/${item.unique_code}.png`,
          },
        },
      ];
      const variables = [
        {
          email: `${item.email}`,
          substitutions: [
            {
              var: "name",
              value: `${item.first_name} ${item.last_name}`,
            },
            {
              var: "company",
              value: `${item.company}`,
            },
          ],
        },
      ];
      const params = {
        recipient: {
          email: `${item.email}`,
          first_name: `${item.first_name}`,
          last_name: `${item.last_name}`,
        },
        subject: global.UF.EVENT_SETTINGS.EMAILS_QR_CODE_SUBJECT,
        personalization: personalization,
        variables: variables,
        template_id: global.UF.EVENT_SETTINGS.EMAILS_QR_CODE_TEMPLATE_ID,
      };
      await global.UF.makeRequest_v2(
        `POST`,
        `/api/qr-code`,
        { attendee_id: item.unique_code },
        true
      );

      const body = {
        params: params,
        event_id: global.UF.event_id,
      };

      await global.UF.makeRequest_v2(
        `POST`,
        `/api/registration/status-mail`,
        body,
        true
      );
      if (callback) {
        callback();
      }
    } catch (err) {
      throw new Error(err.toString());
    }
  };

  handleSendingApprovalEmailBulk = async (rows) => {
    try {
      const promises = rows.map(async (row) => {
        return this.handleSendApprovedEmail(row);
      });
      await Promise.all(promises);
      global.UF.setAlertVisibility(
        true,
        `Approved email was sent to ${rows.length} approved attendees!`,
        "success"
      );
    } catch (err) {
      throw new Error(err.toString());
    }
  };

  renderExportDataModal = () => {
    return (
      <ExportDataModal
        open={this.state.export}
        onClose={() => this.setState({ export: false })}
      />
    );
  };

  renderResendApprovedModal = () => {
    return (
      <ConfirmModalEmail
        open={this.state.emailItem !== null}
        onClose={() => this.setState({ emailItem: null })}
        email={this.state.emailItem ? this.state.emailItem.email : ""}
        onSendEmail={async () =>
          await this.handleSendApprovedEmail(this.state.emailItem, () =>
            this.setState({ emailItem: null })
          )
        }
        text={"Are you sure you want to resend completed email to"}
      />
    );
  };

  handleBeforeAddNew = (dataItem, callback) => {
    dataItem.event_id = global.UF.event_id;
    if (callback) {
      callback();
    }
  };

  findCurrentPermission = () => {
    const user =
      typeof localStorage.getItem("user") === "string"
        ? JSON.parse(localStorage.getItem("user"))
        : localStorage.getItem("user");
    const scopes = user.hasOwnProperty("scopes")
      ? typeof user.scopes === "string"
        ? JSON.parse(user.scopes)
        : user.scopes
      : null;
    this.state.scopeCondition =
      scopes.findIndex(
        (permission) =>
          permission.split(":")[0] === this.state.permission &&
          permission.includes("write")
      ) > -1;
    this.setState({ loaded: true });
  };

  componentDidMount = () => {
    this.getTypeOfAttendees(this.props.router.location.pathname);
    global.UF.dataProvider.datastructure["registration_attendee"].filters = {
      type: this.state.type === "registrations" ? "regular" : this.state.type,
    };
    this.handleCustomGridManipulation();
    this.findCurrentPermission();
  };

  handleInitialSearch = (searchUrl, callback) => {
    if (searchUrl.includes("registration_id")) {
      const search = searchUrl.split("=")[1];
      if (callback) {
        callback(search);
      }
    }
  };

  handleDoubleClick = (params) => {
    const currentAttendee = params.row;
    if (currentAttendee.status === "approved") {
      navigator.clipboard
        .writeText(`${window.location.origin}/api/${currentAttendee.qr_code}`)
        .then(() => {
          alert("Attendee QRCode copied to clipboard");
        });
    }
  };

  customFiltering = (filters) => {
    this.getTypeOfAttendees(this.props.router.location.pathname);
    filters.type =
      this.state.type === "registrations" ? "regular" : this.state.type;
  };

  render() {
    return (
      <Fragment>
        {this.state.export && this.renderExportDataModal()}
        {this.state.emailItem && this.renderResendApprovedModal()}
        <UF_Content
          table="registration_attendee"
          title={this.state.title}
          id={this.props.event_id}
          add={false}
          sync={this.state.scopeCondition}
          edit={this.state.scopeCondition}
          delete={this.state.scopeCondition}
          permission={this.state.permission}
          tools={this.tools}
          buttons={this.buttons}
          popOverButtons={this.popOverButtons}
          onSave={this.onSave}
          onDelete={this.onDelete}
          attendeeType={this.state.type}
          customFiltering={this.customFiltering}
          formConfig={this.formConfig}
          gridProps={this.gridProps}
          beforeAddNew={this.handleBeforeAddNew}
          initialSearch={this.handleInitialSearch}
          rowDoubleClick={this.handleDoubleClick}
        />
      </Fragment>
    );
  }
}

export default withRouter(Attendees);
