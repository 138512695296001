import React from "react";
import PropTypes from "prop-types";
import Nx_Ctrl_TextInput from "./nx_ctrl_textinput";
import Nx_Ctrl_Select from "./nx_ctrl_select";
import Nx_Ctrl_DateTime from "./nx_ctrl_datetime";
import data_types from "../../data/data_types";
import Nx_Ctrl_Checkbox from "./nx_ctrl_checkbox";
import Nx_Ctrl_FileInput from "./nx_ctrl_file";
import uuid from "react-uuid";
import Nx_Ctrl_MultiNumber from "./nx_ctrl_multinumber";
import Nx_Ctrl_Timepicker from "./nx_ctrl_timepicker";

const Nx_Ctrl_Generic = (props) => {
  const [tmpVal, setValue] = React.useState(0);

  const onValueChange = (value) => {
    props.dataItem[props.fieldConfig.field] = value;
    if (props.fieldConfig.hasOwnProperty("onChange")) {
      props.fieldConfig.onChange(props.dataItem);
    }
    setValue(tmpVal + 1);
  };

  const onFileChange = (files) => {
    let extension = files[0].type.split("/")[1];
    files[0].newName = `${uuid().toString()}.${extension}`;
    props.dataItem[props.fieldConfig.field] = files[0].newName;
    if (props.dataItem.hasOwnProperty("files")) {
      props.dataItem[`files`].push(files[0]);
    } else {
      props.dataItem[`files`] = files;
    }
    setValue(tmpVal + 1);
  };

  let validationMsg = false;

  if (props.showValidation && props.fieldConfig.validate) {
    validationMsg = props.fieldConfig.validate(
      props.dataItem,
      props.dataItem[props.fieldConfig.field],
      true
    );
  }

  const renderNumberInput = (fieldConfig) => {
    if (fieldConfig.multiple) {
      return (
        <Nx_Ctrl_MultiNumber
          type={data_types.number}
          values={props.dataItem[props.fieldConfig.field]}
          onChange={onValueChange}
          {...props}
          error={validationMsg != false}
          read_only={props.read_only}
          helperText={
            typeof validationMsg == data_types.string ? validationMsg : ""
          }
        />
      );
    } else {
      return (
        <Nx_Ctrl_TextInput
          type={data_types.number}
          value={props.dataItem[props.fieldConfig.field]}
          read_only={props.read_only}
          onChange={onValueChange}
          {...props}
          error={validationMsg != false}
          helperText={
            typeof validationMsg == data_types.string ? validationMsg : ""
          }
        />
      );
    }
  };

  if (props.fieldConfig.customControl) {
    return props.fieldConfig.customControl(props, onValueChange, validationMsg);
  }

  if (props.fieldConfig.datatype) {
    switch (props.fieldConfig.datatype) {
      case data_types.string:
        return (
          <Nx_Ctrl_TextInput
            value={props.dataItem[props.fieldConfig.field]}
            websitePreview={
              props.fieldConfig.websitePreview && !props.dataItem.isNew
            }
            onChange={onValueChange}
            read_only={props.read_only}
            {...props}
            error={validationMsg != false}
            helperText={
              typeof validationMsg == data_types.string ? validationMsg : ""
            }
          />
        );
      case data_types.color:
        return (
          <Nx_Ctrl_TextInput
            value={props.dataItem[props.fieldConfig.field]}
            onChange={onValueChange}
            type={data_types.color}
            {...props}
            error={validationMsg != false}
            read_only={props.read_only}
            helperText={
              typeof validationMsg == data_types.string ? validationMsg : ""
            }
          />
        );
      case data_types.file:
        return (
          <Nx_Ctrl_FileInput
            value={props.dataItem[props.fieldConfig.field]}
            onChange={onFileChange}
            field={props.fieldConfig.field}
            requirements={
              props.fieldConfig.hasOwnProperty("requirements")
                ? props.fieldConfig.requirements
                : { width: 150, height: 100 }
            }
            type={data_types.file}
            {...props}
            error={validationMsg != false}
            imagePreview={!props.dataItem.isNew}
            label={
              props.fieldConfig.hasOwnProperty("label")
                ? props.fieldConfig.label
                : ""
            }
            read_only={props.read_only}
            helperText={
              typeof validationMsg == data_types.string ? validationMsg : ""
            }
          />
        );
      case data_types.number:
        return renderNumberInput(props.fieldConfig);
      case data_types.boolean:
        return (
          <Nx_Ctrl_Checkbox
            value={props.dataItem[props.fieldConfig.field]}
            read_only={props.read_only}
            onChange={onValueChange}
            {...props}
            error={validationMsg != false}
            helperText={
              typeof validationMsg == data_types.string ? validationMsg : ""
            }
          />
        );
      case data_types.datetime:
      case data_types.date:
        return (
          <Nx_Ctrl_DateTime
            value={props.dataItem[props.fieldConfig.field]}
            read_only={props.read_only}
            onChange={onValueChange}
            {...props}
            error={validationMsg != false}
            helperText={
              typeof validationMsg == data_types.string ? validationMsg : ""
            }
          />
        );
      case data_types.time:
        return (
          <Nx_Ctrl_Timepicker
            value={props.dataItem[props.fieldConfig.field]}
            read_only={props.read_only}
            onChange={onValueChange}
            {...props}
            error={validationMsg != false}
            helperText={
              typeof validationMsg == data_types.string ? validationMsg : ""
            }
          />
        );
      case data_types.reference:
      case data_types.select:
        return (
          <Nx_Ctrl_Select
            value={props.dataItem[props.fieldConfig.field]}
            read_only={props.read_only}
            data={props.fieldConfig.data ? props.fieldConfig.data : null}
            onChange={onValueChange}
            {...props}
            error={validationMsg != false}
            helperText={
              typeof validationMsg == data_types.string ? validationMsg : ""
            }
          />
        );
    }
    return (
      <div>
        {props.fieldConfig.field} - {props.fieldConfig.datatype}
      </div>
    );
  } else {
    return <div />;
  }
};

Nx_Ctrl_Generic.propTypes = {
  dataItem: PropTypes.object,
  fieldConfig: PropTypes.object,
};

export default Nx_Ctrl_Generic;
